import { Component, OnInit } from "@angular/core";
import { ForgotService } from "./forgot.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-forget",
  templateUrl: "./forget.component.html",
  styleUrls: ["./forget.component.css"],
})
export class ForgetComponent implements OnInit {
  loginForm: FormGroup;
  successtoast: any;
  successToast: boolean;
  displayerrormessage: boolean;
  displaymessage: any;
  successMessage: string;
  submititle = "Submit";
  loginfromsubmitted = false;
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    public service: ForgotService
  ) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ]),
    });
  }

  get primEmail() {
    return this.loginForm.get("username");
  }

  onSubmit() {
    this.loginfromsubmitted = true;
    // using api
    if (this.loginForm.invalid) {
      return;
    }
    this.successToast = false;
    this.submititle = "Submitting ...";
    this.service.addHero(this.loginForm.value).subscribe(
      (res: any) => {
        this.successToast = true;
        this.successtoast = res.message;
        this.displayerrormessage = false;
        this.submititle = "Submit";
        if (res.success) {
          this.submititle = "Submit";
          this.successMessage = "OTP sent to Register email Id";
          const username = this.loginForm.value.username;
          localStorage.setItem("username", username);
          setTimeout((res: any) => {
            this.router.navigate(["./verify-otp"]);
          }, 1500);
        }
      },
      (error) => {
        if (!error.error.success) {
          this.displayerrormessage = true;
          this.displaymessage = error.error.message;
          this.submititle = "Submit";
          this.loginForm.reset();
          this.loginfromsubmitted = false;
        }
      }
    );
  }
}
