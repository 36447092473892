import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { AdminService } from "../admin.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";
import * as FileSaver from "file-saver";
import * as moment from "moment";

declare var $: any;

@Component({
  selector: "app-admin-resume",
  templateUrl: "./admin-resume.component.html",
  styleUrls: ["./admin-resume.component.css"],
})
export class AdminResumeComponent implements OnInit {
  displayerrormessage: boolean;
  loginForm: FormGroup;
  successtoast: any;
  successToast: boolean;
  displaymessage: any;
  displayerroraccess: boolean;
  file: any;
  items = [];
  submittitle = "Add";
  requirederrors: boolean;
  loginfromsubmitted = false;
  tableData: any[] = [];
  p: any;

  FIRST_NAME;
  CREATED_DATE;
  EMAIL_ID;
  POSITION;
  LOCATION;
  searchText;
  headers = [
    "S./NO",
    "FIRST NAME",
    "LAST NAME",
    "EMAIL ID",
    "POSITION",
    "LOCATION",
    "WILLING TO TRAVEL",
    "USED SOFTWARE",
    "CREATED DATE",
    "DOWNLOAD",
  ];
  icons = {
    filter: " ",
  };
  columnDefs = [
    {
      headerName: "FIRST NAME",
      field: "FIRST_NAME",
      sortable: true,
      filter: true,
      width: 150,
      resizable: true
    },
    {
      headerName: "LAST NAME",
      field: "LAST_NAME",
      sortable: true,
      filter: true,
      width: 150,
      resizable: true
    },
    {
      headerName: "EMAIL ID",
      field: "EMAIL_ID",
      sortable: true,
      filter: true,
      width: 250,
      resizable: true
    },
    {
      headerName: "POSITION",
      field: "POSITION",
      sortable: true,
      filter: true,
      width: 140,
      resizable: true
    },
    {
      headerName: "LOCATION",
      field: "LOCATION",
      sortable: true,
      filter: true,
      width: 130,
      resizable: true
    },
    {
      headerName: "USED SOFTWARE",
      field: "USED_SOFTWARE",
      sortable: true,
      filter: true,
      width: 150,
      resizable: true
    },
    {
      headerName: "WILLING TO TRAVEL",
      field: "WILLING_TO_TRAVEL",
      sortable: true,
      filter: true,
      width: 180,
      resizable: true
    },
    {
      headerName: "CREATED DATE",
      field: "CREATED_DATE",
      suppressFilter: false,
      width: 115,
      resizable: true
    },
    {
      headerName: "UPDATED DATE",
      field: "UPDATED_DATE",
      width: 115,
      suppressFilter: false,
      cellRenderer: (params) => {
        return params.value? moment(params.value).format('MM-DD-YYYY'):''
    },
    resizable: true
    },
    {
      headerName: "DOWNLOAD",
      field: "preSignedURL",
      sortable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          "<span><a href=" + params.value + ' rel="noopener"> <i class="fa fa-download download-icon" aria-hidden="true"></i></a></span>'
        );
      },
      width: 100,
      cellStyle: {'display': 'flex', 'justify-content': 'center', 'align-items':'center', },
      resizable: true
    },
    {
      headerName: "ACTIONS",
      sortable: false,
      filter: false,
      cellRenderer: function (params) {
        return (
          `<span><i class="fa fa-trash" aria-hidden="true"></i></span>`
        );
      },
      width: 80,
      cellStyle: {'display': 'flex', 'justify-content': 'center', 'align-items':'center'}
    },
  ];
  deleteResumeId:any;
  responseMessage:any;
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private service: AdminService
  ) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      email_id: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ]),
      first_name: new FormControl("", [Validators.required]),
      last_name: new FormControl("", [Validators.required]),
      position: new FormControl("", [Validators.required]),
      location: new FormControl("", [Validators.required]),
      file: new FormControl(this.file, [Validators.required]),
    });

    this.service.getResume().subscribe((res: any) => {
      this.tableData = res.data;
      this.createdIndex();
    });
  }

  get primEmail() {
    return this.loginForm.get("email_id");
  }
  openmodal() {
    this.loginfromsubmitted = false;
  }

  // file select
  fileBrowseHandler(files) {
    this.file = files.item(0);
  }

  // Upload resume content
  onSubmit() {
    this.loginfromsubmitted = true;
    // using api
    if (this.loginForm.invalid) {
      return;
    }
    this.successToast = false;
    this.requirederrors = false;
    this.displayerrormessage = false;
    let name = this.file.name.split(".").pop();
    name = name.toLowerCase();
    let form = new FormData();
    form.append("email_id", this.loginForm.value.email_id);
    form.append("first_name", this.loginForm.value.first_name);
    form.append("last_name", this.loginForm.value.last_name);
    form.append("position", this.loginForm.value.position);
    form.append("location", this.loginForm.value.location);
    form.append("file", this.file);
    if (name == "pdf" || name == "docx") {
      this.submittitle = "Adding...";
      this.service.uploadresume(form).subscribe(
        (res) => {
          this.successToast = true;
          this.displayerrormessage = false;
          if (res.success) {
            this.successtoast = res.message;
            this.submittitle = "Add";
            this.loginForm.reset();
            this.loginfromsubmitted = false;
            setTimeout(function () {
              $("#myModal").modal("hide");
              window.location.reload();
            }, 3000);
          }
        },
        (error) => {
          if (!error.error.success) {
            this.displayerrormessage = true;
            this.displaymessage = error.error.message;
          }
        }
      );
    } else {
      this.displayerrormessage = true;
      this.displaymessage =
        "The selected file is not supported. Please select the file format PDF, or DOCX";
    }
  }
  closemodal() {
    $("#myModal").modal("hide");
    this.loginForm.reset();
  }
  download(item) {
    window.open(item.preSignedURL);
  }

  createdIndex() {
    this.tableData.forEach((row, i) => (row["s_no"] = i + 1));
  }
  onCellClicked(event){
    if(event.colDef.headerName == 'ACTIONS'){
      this.deleteResumeId = event.data.RESUME_ID
      $("#deleteModal").modal("show");
    }
  }
  deleteConfirm(){
    this.responseMessage = '';
    this.service.deleteResume({ID:this.deleteResumeId}).subscribe(
      (response) => {
        this.responseMessage = response.message;
        setTimeout(function () {
          $("#deleteModal").modal("hide");
              window.location.reload();
            }, 3000);
      },(error)=>{
        this.responseMessage = error.error.message;
      })
  }
}
