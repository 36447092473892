import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()

export class AdminCareerService {
    constructor(public http: HttpClient) { }

    addCareer(data: any){
        return this.http.post(`${environment.apiUrl}careers/add` , data, {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    getCareers(){
        return this.http.get(`${environment.apiUrl}careers` , {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    getCareer(id){
        return this.http.get(`${environment.apiUrl}careers/${id}` , {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    updateCareer(data: any){
        return this.http.put(`${environment.apiUrl}careers/update` , data, {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    deleteCareer(data: any){
        return this.http.put(`${environment.apiUrl}careers/delete` , data, {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    addTechnology(data: any){
        return this.http.post(`${environment.apiUrl}technologies/add` , data, {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    getTechnologies(){
        return this.http.get(`${environment.apiUrl}technologies` , {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    updateTechnology(data: any){
        return this.http.put(`${environment.apiUrl}technologies/update` , data, {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    deleteTechnology(data: any){
        return this.http.put(`${environment.apiUrl}technologies/delete` , data, {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("x-token-code", localStorage.getItem("x-token-code"));
    return headers;
    }

    getCareerResumes(){
        return this.http.get(`${environment.apiUrl}careersresumes` , {headers: this.getHeaders()})
        .pipe(map((response: any) => response));
    }

    deleteResume(data) {
        return this.http.put(`${environment.apiUrl}careersresumes/delete`, data, {headers: this.getHeaders()})
          .pipe(map((response: any) => response));
      }

}

