import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  AfterViewChecked,
} from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, Params } from "@angular/router";
import * as $ from "jquery";
import { DataService } from "../data.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormGroupDirective,
  FormControl,
} from "@angular/forms";
import { OnlineservicesmailerService } from "../onlineservices/onlineservicesmailer.service";
import "rxjs/add/operator/pairwise";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ɵparseCookieValue } from "@angular/common";
import { EventEmitterService } from "../event-emitter.service";
import { data } from "jquery";

@Component({
  selector: "app-workorderform",
  templateUrl: "./workorderform.component.html",
  styleUrls: ["./workorderform.component.css"],
})
export class workorderformComponent implements OnInit {
  projectTeam: string;
  dataService: DataService;
  changeOfAddressForm: FormGroup;
  contractServiceForm: FormGroup;
  submitting = "Submit";
  requestw2Form: FormGroup;
  directDeposit: FormGroup;
  lostStolenChecksForm: FormGroup;
  workorderForm: FormGroup;
  suppliesForm: FormGroup;
  injuryreport: FormGroup;
  responeApi: any = "";
  receivedresponse: any = "";
  receivedresponseCode: any;
  receivedresponseMessage: any;
  sectionScroll: any;
  suppliesfragment: string;
  injuryfragment: string;
  workorderfragment: string;
  formsfragment: string;
  workorderfragmentboolean = false;
  formsfragmentboolean = false;
  suppliesfragmentboolean = false;
  injuryfragmentboolean = false;
  workersCompfragmentboolean = false;
  submittitle = "Submit";
  disableTextbox = "";
  @ViewChild("workorderform") divClick: ElementRef;
  // @ViewChild("fileInput") fileInput;
  wrkorderbody = " ";
  suppliesbody = " ";
  injurybody = " ";
  formsbody = " ";
  workersCompbody = " ";
  changeOfAddresssubmitSubmitted = false;
  directDepositSubmitSubmitted = false;
  contractSeriveLetterSubmitted = false;
  lostStolenMissingSubmitted = false;
  Requestw2Submitted = false;
  workorderFormsubmitted = false;
  suppliesFormsubmitted = false;
  workerCompensationformsubmitted = false;
  responsemessageboolean = false;
  disabledboolean = false;
  employersFragment: any;
  urlValue: any;
  workerCompensationform: FormGroup;
  successtoast: any;
  successToast: boolean;
  displaymessage: boolean;
  displayerrormessage: boolean;
  emptyfields: boolean;
  injuryreportsubmitted = false;
  buttonDisabled = false;
  filesFirst:any = [];
  filesSecond:any = [];
  payrollSubmissionForm: FormGroup;
  payrollSubmissionfragmentboolean = false;
  payrollSubmissionbody = " ";
  payrollSubmissionFormsubmitted = false;

  constructor(
    private router: Router,
    private dService: DataService,
    private fb: FormBuilder,
    private _onlineservicesmailer: OnlineservicesmailerService,
    private activatedRoute: ActivatedRoute,
    private eventEmitterService: EventEmitterService
  ) {
 
    this.dataService = dService;
    this.dataService.setScrolled(false);
    window.onresize = (e) => { };
    //     this.activatedRoute.url.subscribe(url =>{

    //  });
    this.workorderfragment = activatedRoute.snapshot.fragment;

    if (this.workorderfragment === "workorderform") {
      const paramter = this.workorderfragment;

      this.scrollToBottom();
    } else if (this.workorderfragment === "employers") {
      // this.scrollbyId('employers');
    } else {
      this.scrollToTop();
    }
   
  }

  ngOnInit() {
    this.eventEmitterService.subsVar = this.eventEmitterService.invokeFormComponentFunction
      .subscribe((data: any) => {
        if (data == "injury") {
          this.workersCompfragmentboolean = false;
          this.workersCompbody = " ";
        } else if (data == "wc-cert") {
          this.scrollToBottomWorkersComp();
          this.injuryfragmentboolean = false;
          this.injurybody = " ";
        }
      })
    this.workorderForm = this.fb.group({
      existingclient: "",
      newclient: "",
      parentcmpyinfo: "",
      parentcmpyname: ["", [Validators.required]],
      parentcmpyadd: ["", [Validators.required]],
      parentcmpystate: ["", [Validators.required]],
      parentcmpycity: ["", [Validators.required]],
      parentcmpyzip: ["", [Validators.required]],
      parentcmpyphone: ["", [Validators.required]],
      parentcmpyein: ["", [Validators.required]],
      stateregister: "",
      productionconame: "",
      productioncoadd: "",
      productioncostate: "",
      productioncocity: "",
      productioncozip: "",
      productioncophone: "",
      productioncmpyein: ["", [Validators.required]],
      prductnstateres: "",
      productioncocontact: ["", [Validators.required]],
      productioncontitle: ["", [Validators.required]],
      productioncoemail: ["", [
        Validators.email,
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ]],
      productioncntctphone: ["", [Validators.required]],
      projectname: "",
      Theatrical: "",
      lowbudget: "",
      Standard: "",
      HighBudget: "",
      Television: "",
      Televisionmow: "",
      Televisionpilot: "",
      Televisionepisode: "",
      Televisionminiseries: "",
      TelevisionMinilenghtInput: "",
      TelevisionepisodeInput: "",
      TelevisionminiInput: "",
      cablevalue: "",
      paycablevalue: "",
      Cablemow: "",
      Cablepilot: "",
      Cableepisodic: "",
      CableepisodicInput: "",
      Cableminiseries: "",
      CableMiniInput: "",
      CableMinilenghtInput: "",
      newmediavalue: '',
      newmediamow: '',
      newmediapilot: '',
      newmediaepisode: '',
      newmediaepisodeofeps: '',
      newmediaminiseriesInput: '',
      newmediaminiseriesfirstValue: '',
      newmediaminiseriesLength: '',
      OtherValue: '',
      OtherMusic: '',
      OtherLive: '',
      OtherDocumentry: '',
      OtherStaff: '',
      OtherPostProduction: '',
      OtherIndustrial: '',
      CommercialOther: '',
      CommercialValue: '',
      CommercialValueTelevisiom: '',
      CommercialValueRadio: '',
      prjctstartdate: "",
      workloctnadd: "",
      Countyvalue: "",
      foreignlocations: "",
      payrollepisodebudget: "",
      prductnbudget: "",
      pcardyes: '',
      payrollbudget: "",
      Budgetyes: "",
      DGAcheckbox: "",
      DGAtext: "",
      DGAdepositeamt: "",
      SAGcheckbox: "",
      SAGtext: "",
      SAGrepname: "",
      SAGemail: "",
      SAGphone: "",
      SAGdepositeamt: "",
      SAGsignatoryid: '',
      SAGproductionid: '',
      sideletteranyappfirst: '',
      sideletteranyappsecond: '',
      sideletterone: '',
      sidelettertwo: '',
      residualsprocessing: '',
      castLisiting: '',
      fullservice: '',
      residualscontact:'',
      residualsphone: '',
      residualsemail: '',
      costcodestructure: '',
      ProcalProductionCalendar: '',
      ProAnalytics: '',
      ProBudget: '',
      WGAcheckbox: "",
      WGAtext: "",
      WGAdepositamt: "",
      AFTRAcheckbox: "",
      AFTRAtext: "",
      minorscheckbox: "",
      nonunioncheckbox: "",
      IATSEcheckbox: "",
      IATSEtext: "",
      IATSEdepositamt: "",
      teamsterscheckbox: "",
      teamsterstext: "",
      AFMcheckbox: "",
      AFMtext: "",
      taxincentiveyes: "",
      applicablestates: "",
      crew: "",
      talent: "",
      editorial: "",
      extras: "",
      officestaff: "",
      minors: "",
      payrollcontact: "",
      payrollcontactphone: "",
      payrollcntphone: "",
      payrollothercon: "",
      stuntactivity: "",
      costcodestruct: "",
      paymentterms: "",
      payperiod: [null, [Validators.required]],
      payfreqence: [null, [Validators.required]],
      paydaydropdown: [null, [Validators.required]],
      Specifytext: "",
      generalcomments: "",
      mailemp: "",
      clientpickup: "",
      deliver: "",
      paychecktext: "",
      wiretrans: "",
      cashiercheck: "",
      ACHdebit: "",
      cmpycheck: "",
      prepdate: "",
      startshoot: "",
      endshoot: "",
      commentforbudget: "",
      directdeposite: "",
      remoteprinting: "",
      actnsoftware: [null, [Validators.required]],
      accntsoftwareyes: "",
      fileexport: "",
      startapprover1: "",
      startapprover2: "",
      startapprover4: "",
      startappemail1: "",
      startappemail3: "",
      startappemail4: "",
      startappdep1: "",
      startappdep2: "",
      startappdep4: "",
      timecardapp1: "",
      timecardapp2: "",
      timecardapp4: "",
      timecardemail1: "",
      timecardemail2: "",
      timecardemail4: "",
      timecarddep1: "",
      timecarddep2: "",
      timecarddep4: "",
      empcreateown: "",
      depthead: "",
      both: "",
      Upfront: "",
      Accrue: "",
      ACAabligationyes: "",
      ACAgrpstrctyes: "",
      nonunionvalue: "",
      networkDistributor: "",
      deliveryDate: "",
      bondCompany: "",
      financier: "",
      custyes: "",
      custno: "",
      sickLeaveother: "",
      homestate: "",
      workState: "",
      posPay: "",
      checkingAccount: "",
      routingAccount: "",
      calSavers: "",
      diversity: "",
      harassmentTraining: "",
      projectSummary: "",
      accountingsoftwarebankname: "",
      accountingsoftwarebankaddress: "",
      accountingsoftwarename: "",
      accountingsoftwareemail: ""
    });
    this.workerCompensationform = this.fb.group({
      productionCompanyName: ["", Validators.required],
      productionCompanyAddress: ["", Validators.required],
      projectName: ["", Validators.required],
      requesterName: ["", Validators.required],
      requesterPhoneNumber: ["", Validators.required],
      requesterEmailAddress: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });


    $(document).ready(function () {
      // toggle the component with class accordion_body
      $(".accordion_head").click(function () {
        if ($(".accordion_body").is(":visible")) {
          $(".accordion_body").slideUp(300);
          $(".plusminus").text("+");
        }
        if ($(this).next(".accordion_body").is(":visible")) {
          $(this).next(".accordion_body").slideUp(300);
          $(this).children(".plusminus").text("+");
        } else {
          $(this).next(".accordion_body").slideDown(300);
          $(this).children(".plusminus").text("-");
        }
      });
    });
  }
  ngAfterViewInit() {
    // Scroll (Anchor) Function to Order History
    this.activatedRoute.fragment.subscribe( fragment =>{
      if (fragment) {
        this.scrollbyId(fragment);
      }
    })
  }
  scrollbyId(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }

  // tslint:disable-next-line:use-life-cycle-interface
  onCheckboxChange(checkBoxValue: string) {
    if(checkBoxValue){
      this.workorderForm.get("productionconame").enable();
      this.workorderForm.get("productioncoadd").enable();
      this.workorderForm.get("productioncostate").enable();
      this.workorderForm.get("productioncocity").enable();
      this.workorderForm.get("productioncozip").enable();
      this.workorderForm.get("productioncophone").enable();
      this.workorderForm.get("productioncmpyein").enable();
      this.workorderForm.get("prductnstateres").enable();
      this.workorderForm.patchValue({ productionconame: this.workorderForm.value.parentcmpyname });
      this.workorderForm.patchValue({ productioncoadd: this.workorderForm.value.parentcmpyadd });
      this.workorderForm.patchValue({ productioncostate: this.workorderForm.value.parentcmpystate });
      this.workorderForm.patchValue({ productioncocity: this.workorderForm.value.parentcmpycity });
      this.workorderForm.patchValue({ productioncozip: this.workorderForm.value.parentcmpyzip });
      this.workorderForm.patchValue({ productioncophone: this.workorderForm.value.parentcmpyphone });
      this.workorderForm.patchValue({ productioncofax: this.workorderForm.value.parentcmpyfax });
      this.workorderForm.patchValue({ productioncmpyein: this.workorderForm.value.parentcmpyein });
      this.workorderForm.patchValue({ prductnstateres: this.workorderForm.value.stateregister });
      this.disabledboolean = true;
    }
    else{
      this.disabledboolean = false;
      this.workorderForm.patchValue({ productionconame: ''})
      this.workorderForm.patchValue({ productioncoadd: '' });
      this.workorderForm.patchValue({ productioncostate: '' });
      this.workorderForm.patchValue({ productioncocity: '' });
      this.workorderForm.patchValue({ productioncozip: '' });
      this.workorderForm.patchValue({ productioncophone: '' });
      this.workorderForm.patchValue({ productioncofax: '' });
      this.workorderForm.patchValue({ productioncmpyein: '' });
      this.workorderForm.patchValue({ prductnstateres: '' });
    }
    
  }

  existingclient(feildName) {
    this.workorderForm.value["" + feildName] = this.workorderForm.value[
      "" + feildName
    ]
      ? "Yes"
      : "No";
  }
  changeOfAddresssubmit() {
    this.changeOfAddresssubmitSubmitted = true;
    if (this.changeOfAddressForm.invalid) {
      return;
    }
    this.submitting = "Sending Request...";
    this.buttonDisabled = true;
    this._onlineservicesmailer
      .emailService(this.changeOfAddressForm.value)
      .subscribe((data) => {
        this.responeApi = data;
        this.sendrespose(this.responeApi);
        this.changeOfAddressForm.reset();
        this.changeOfAddresssubmitSubmitted = false;
        this.submitting = "Submit";
        this.buttonDisabled = false;
      });
  }
  Requestw2submit() {
    this.Requestw2Submitted = true;
    if (this.requestw2Form.invalid) {
      return;
    }
    this.submitting = "Sending Request...";
    this.buttonDisabled = true;
    this._onlineservicesmailer
      .requestW2(this.requestw2Form.value)
      .subscribe((data) => {
        this.responeApi = data;
        this.sendrespose(this.responeApi);
        this.requestw2Form.reset();
        this.Requestw2Submitted = false;
        this.submitting = "Submit";
        this.buttonDisabled = false;
      });
  }
  lostStolenMissingChecks() {
    this.lostStolenMissingSubmitted = true;
    if (this.lostStolenChecksForm.invalid) {
      return;
    }
    this.submitting = "Sending Request...";
    this.buttonDisabled = true;
    this._onlineservicesmailer
      .lostStolenChecks(this.lostStolenChecksForm.value)
      .subscribe((data) => {
        this.responeApi = data;
        this.sendrespose(this.responeApi);
        this.lostStolenChecksForm.reset();
        this.lostStolenMissingSubmitted = false;
        this.submitting = "Submit";
        this.buttonDisabled = false;
      });
  }

  fileChangeEvent(files: any, i:any){
    if(i == 0){
      this.filesFirst = files.item(0)
    }else{
      this.filesSecond =  files.item(0)
    }
}
  workorderFormsubmit() {
  
    let workOrderFormData = this.getWorkOrderFormData()
    this.workorderFormsubmitted = true;
    // using api
    if (this.workorderForm.invalid) {
      return;
    }
    this.submitting = "Sending Request...";
    this.buttonDisabled = true;
    this._onlineservicesmailer
      .workorderForm(workOrderFormData)
      .subscribe((data) => {
        this.responeApi = data;
        this.sendrespose(this.responeApi);
        this.workorderForm.reset();
        this.workorderFormsubmitted = false;
        this.submitting = "Submit";
        this.buttonDisabled = false;
        this.filesFirst = []
        this.filesSecond = []
      });
  }
  sendrespose(para) {
    this.responsemessageboolean = true;
    this.receivedresponse = para;
    this.receivedresponseCode = 1;
    this.receivedresponseMessage = this.receivedresponse.Status;
    setTimeout(
      function () {
        this.receivedresponseMessage = " ";
      }.bind(this),
      3000
    );

    Swal.fire({
      title: "Thank You !",
      text: "Mail Sent Succesfully",
      showCancelButton: false,
    });
    this.disabledboolean = false;
  }
  scrollToBottom(): void {
    setTimeout(() => {
      window.scrollTo({ left: 0, top: 615, behavior: "smooth" });
      this.openaccordian();
    }, 100);
  }
  scrollToBottomforms(): void {
    setTimeout(() => {
      window.scrollTo({ left: 0, top: 500, behavior: "smooth" });
      this.openaccordianforms();
    }, 100);
  }
  scrollToBottomWorkersComp(): void {
    setTimeout(() => {
      window.scrollTo({ left: 0, top: 600, behavior: "smooth" });
      this.openaccordianWorkersComp();
    }, 100);
  }
  openaccordian() {
    this.wrkorderbody = "block ";
    this.workorderfragmentboolean = true;
  }
  openaccordianforms() {
    this.formsbody = "block";
    this.formsfragmentboolean = true;
  }
  openaccordianWorkersComp() {
    this.workersCompbody = "block";
    this.workersCompfragmentboolean = true;
  }
  scrollToTop() {
    setTimeout(() => {
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    }, 100);
  }
  wcform() {
    this.workerCompensationformsubmitted = true;
    if (this.workerCompensationform.invalid) {
      return;
    }
    this.successToast = false;
    this.submittitle = "Submitting ...";
    this.buttonDisabled = true;
    this.dService.uploadwcform(this.workerCompensationform.value).subscribe(
      (res: any) => {
        if (res.StatusCode === 1) {
          this.submittitle = "Submit";
          this.successToast = true;
          this.emptyfields = false;
          this.successtoast = res.Status;
          this.workerCompensationform.reset()
          this.workerCompensationformsubmitted = false;
          this.buttonDisabled = false;
        }
      },
      (error) => {
        if (!error.error.success) {
          this.displayerrormessage = true;
          this.displaymessage = error.error.message;
        }
      }
    );
  }

  getWorkOrderFormData(){
    const formData = new FormData();
    formData.append("existingclient", this.workorderForm.value.existingclient);
    formData.append("newclient", this.workorderForm.value.newclient);
    formData.append("parentcmpyinfo", this.workorderForm.value.parentcmpyinfo);
    formData.append("parentcmpyname", this.workorderForm.value.parentcmpyname);
    formData.append("parentcmpyadd", this.workorderForm.value.parentcmpyadd);
    formData.append("parentcmpystate", this.workorderForm.value.parentcmpystate);
    formData.append("parentcmpycity", this.workorderForm.value.parentcmpycity);
    formData.append("parentcmpyzip", this.workorderForm.value.parentcmpyzip);
    formData.append("parentcmpyphone", this.workorderForm.value.parentcmpyphone);
    formData.append("parentcmpyein", this.workorderForm.value.parentcmpyein);
    formData.append("stateregister", this.workorderForm.value.stateregister);
    formData.append("productionconame", this.workorderForm.value.productionconame);
    formData.append("productioncoadd", this.workorderForm.value.productioncoadd);
    formData.append("productioncostate", this.workorderForm.value.productioncostate);
    formData.append("productioncocity", this.workorderForm.value.productioncocity);
    formData.append("productioncozip", this.workorderForm.value.productioncozip);
    formData.append("productioncophone", this.workorderForm.value.productioncophone);
    formData.append("productioncmpyein", this.workorderForm.value.productioncmpyein);
    formData.append("prductnstateres", this.workorderForm.value.prductnstateres);
    formData.append("productioncocontact", this.workorderForm.value.productioncocontact);
    formData.append("productioncontitle", this.workorderForm.value.productioncontitle);
    formData.append("productioncoemail", this.workorderForm.value.productioncoemail);
    formData.append("productioncntctphone", this.workorderForm.value.productioncntctphone);
    formData.append("projectname", this.workorderForm.value.projectname);
    formData.append("Theatrical", this.workorderForm.value.Theatrical);
    formData.append("lowbudget", this.workorderForm.value.lowbudget);
    formData.append("Standard", this.workorderForm.value.Standard);
    formData.append("HighBudget", this.workorderForm.value.HighBudget);
    formData.append("Television", this.workorderForm.value.Television);
    formData.append("Televisionmow", this.workorderForm.value.Televisionmow);
    formData.append("Televisionpilot", this.workorderForm.value.Televisionpilot);
    formData.append("Televisionepisode", this.workorderForm.value.Televisionepisode);
    formData.append("Televisionminiseries", this.workorderForm.value.Televisionminiseries);
    formData.append("TelevisionMinilenghtInput", this.workorderForm.value.TelevisionMinilenghtInput);
    formData.append("TelevisionepisodeInput", this.workorderForm.value.TelevisionepisodeInput);
    formData.append("TelevisionminiInput", this.workorderForm.value.TelevisionminiInput);
    formData.append("cablevalue", this.workorderForm.value.cablevalue);
    formData.append("paycablevalue", this.workorderForm.value.paycablevalue);
    formData.append("Cablemow", this.workorderForm.value.Cablemow);
    formData.append("Cablepilot", this.workorderForm.value.Cablepilot);
    formData.append("Cableepisodic", this.workorderForm.value.Cableepisodic);
    formData.append("CableepisodicInput", this.workorderForm.value.CableepisodicInput);
    formData.append("Cableminiseries", this.workorderForm.value.Cableminiseries);
    formData.append("CableMiniInput", this.workorderForm.value.CableMiniInput);
    formData.append("CableMinilenghtInput", this.workorderForm.value.CableMinilenghtInput);
    formData.append("newmediavalue", this.workorderForm.value.newmediavalue);
    formData.append("newmediamow", this.workorderForm.value.newmediamow);
    formData.append("newmediapilot", this.workorderForm.value.newmediapilot);
    formData.append("newmediaepisode", this.workorderForm.value.newmediaepisode);
    formData.append("newmediaepisodeofeps", this.workorderForm.value.newmediaepisodeofeps);
    formData.append("newmediaminiseriesInput", this.workorderForm.value.newmediaminiseriesInput);
    formData.append("newmediaminiseriesfirstValue", this.workorderForm.value.newmediaminiseriesfirstValue);
    formData.append("newmediaminiseriesLength", this.workorderForm.value.newmediaminiseriesLength);
    formData.append("OtherValue", this.workorderForm.value.OtherValue);
    formData.append("OtherMusic", this.workorderForm.value.OtherMusic);
    formData.append("OtherLive", this.workorderForm.value.OtherLive);
    formData.append("OtherDocumentry", this.workorderForm.value.OtherDocumentry);
    formData.append("OtherStaff", this.workorderForm.value.OtherStaff);
    formData.append("OtherPostProduction", this.workorderForm.value.OtherPostProduction);
    formData.append("OtherIndustrial", this.workorderForm.value.OtherIndustrial);
    formData.append("CommercialOther", this.workorderForm.value.CommercialOther);
    formData.append("CommercialValue", this.workorderForm.value.CommercialValue);
    formData.append("CommercialValueTelevisiom", this.workorderForm.value.CommercialValueTelevisiom);
    formData.append("CommercialValueRadio", this.workorderForm.value.CommercialValueRadio);
    formData.append("prjctstartdate", this.workorderForm.value.prjctstartdate);
    formData.append("workloctnadd", this.workorderForm.value.workloctnadd);
    formData.append("Countyvalue", this.workorderForm.value.Countyvalue);
    formData.append("foreignlocations", this.workorderForm.value.foreignlocations);
    formData.append("payrollepisodebudget", this.workorderForm.value.payrollepisodebudget);
    formData.append("prductnbudget", this.workorderForm.value.prductnbudget);
    formData.append("pcardyes", this.workorderForm.value.pcardyes);
    formData.append("payrollbudget", this.workorderForm.value.payrollbudget);
    formData.append("Budgetyes", this.workorderForm.value.Budgetyes);
    formData.append("DGAcheckbox", this.workorderForm.value.DGAcheckbox);
    formData.append("DGAtext", this.workorderForm.value.DGAtext);
    formData.append("DGAdepositeamt", this.workorderForm.value.DGAdepositeamt);
    formData.append("SAGcheckbox", this.workorderForm.value.SAGcheckbox);
    formData.append("SAGtext", this.workorderForm.value.SAGtext);
    formData.append("SAGrepname", this.workorderForm.value.SAGrepname);
    formData.append("SAGemail", this.workorderForm.value.SAGemail);
    formData.append("SAGphone", this.workorderForm.value.SAGphone);
    formData.append("SAGdepositeamt", this.workorderForm.value.SAGdepositeamt);
    formData.append("SAGsignatoryid", this.workorderForm.value.SAGsignatoryid);
    formData.append("SAGproductionid", this.workorderForm.value.SAGproductionid);
    formData.append("sideletteranyappfirst", this.workorderForm.value.sideletteranyappfirst);
    formData.append("sideletteranyappsecond", this.workorderForm.value.sideletteranyappsecond);
    formData.append("sideletterone", this.workorderForm.value.sideletterone);
    formData.append("sidelettertwo", this.workorderForm.value.sidelettertwo);
    formData.append("residualsprocessing", this.workorderForm.value.residualsprocessing);
    formData.append("castLisiting", this.workorderForm.value.castLisiting);
    formData.append("fullservice", this.workorderForm.value.fullservice);
    formData.append("residualscontact", this.workorderForm.value.residualscontact);
    formData.append("residualsphone", this.workorderForm.value.residualsphone);
    formData.append("residualsemail", this.workorderForm.value.residualsemail);
    formData.append("costcodestructure", this.workorderForm.value.costcodestructure);
    formData.append("ProcalProductionCalendar", this.workorderForm.value.ProcalProductionCalendar);
    formData.append("ProAnalytics", this.workorderForm.value.ProAnalytics);
    formData.append("ProBudget", this.workorderForm.value.ProBudget);
    formData.append("WGAcheckbox", this.workorderForm.value.WGAcheckbox);
    formData.append("WGAtext", this.workorderForm.value.WGAtext);
    formData.append("WGAdepositamt", this.workorderForm.value.WGAdepositamt);
    formData.append("AFTRAcheckbox", this.workorderForm.value.AFTRAcheckbox);
    formData.append("AFTRAtext", this.workorderForm.value.AFTRAtext);
    formData.append("minorscheckbox", this.workorderForm.value.minorscheckbox);
    formData.append("nonunioncheckbox", this.workorderForm.value.nonunioncheckbox);
    formData.append("IATSEcheckbox", this.workorderForm.value.IATSEcheckbox);
    formData.append("IATSEtext", this.workorderForm.value.IATSEtext);
    formData.append("IATSEdepositamt", this.workorderForm.value.IATSEdepositamt);
    formData.append("teamsterscheckbox", this.workorderForm.value.teamsterscheckbox);
    formData.append("teamsterstext", this.workorderForm.value.teamsterstext);
    formData.append("AFMcheckbox", this.workorderForm.value.AFMcheckbox);
    formData.append("AFMtext", this.workorderForm.value.AFMtext);
    formData.append("taxincentiveyes", this.workorderForm.value.taxincentiveyes);
    formData.append("applicablestates", this.workorderForm.value.applicablestates);
    formData.append("crew", this.workorderForm.value.crew);
    formData.append("talent", this.workorderForm.value.talent);
    formData.append("editorial", this.workorderForm.value.editorial);
    formData.append("extras", this.workorderForm.value.extras);
    formData.append("officestaff", this.workorderForm.value.officestaff);
    formData.append("minors", this.workorderForm.value.minors);
    formData.append("payrollcontact", this.workorderForm.value.payrollcontact);
    formData.append("payrollcontactphone", this.workorderForm.value.payrollcontactphone);
    formData.append("payrollcntphone", this.workorderForm.value.payrollcntphone);
    formData.append("payrollothercon", this.workorderForm.value.payrollothercon);
    formData.append("stuntactivity", this.workorderForm.value.stuntactivity);
    formData.append("costcodestruct", this.workorderForm.value.costcodestruct);
    formData.append("paymentterms", this.workorderForm.value.paymentterms);
    formData.append("payfreqence", this.workorderForm.value.payfreqence);
    formData.append("payperiod", this.workorderForm.value.payperiod);
    formData.append("paydaydropdown", this.workorderForm.value.paydaydropdown);
    formData.append("Specifytext", this.workorderForm.value.Specifytext);
    formData.append("generalcomments", this.workorderForm.value.generalcomments);
    formData.append("mailemp", this.workorderForm.value.mailemp);
    formData.append("clientpickup", this.workorderForm.value.clientpickup);
    formData.append("deliver", this.workorderForm.value.deliver);
    formData.append("paychecktext", this.workorderForm.value.paychecktext);
    formData.append("wiretrans", this.workorderForm.value.wiretrans);
    formData.append("cashiercheck", this.workorderForm.value.cashiercheck);
    formData.append("ACHdebit", this.workorderForm.value.ACHdebit);
    formData.append("cmpycheck", this.workorderForm.value.cmpycheck);
    formData.append("prepdate", this.workorderForm.value.prepdate);
    formData.append("startshoot", this.workorderForm.value.startshoot);
    formData.append("endshoot", this.workorderForm.value.endshoot);
    formData.append("commentforbudget", this.workorderForm.value.commentforbudget);
    formData.append("directdeposite", this.workorderForm.value.directdeposite);
    formData.append("remoteprinting", this.workorderForm.value.remoteprinting);
    formData.append("actnsoftware", this.workorderForm.value.actnsoftware);
    formData.append("accntsoftwareyes", this.workorderForm.value.accntsoftwareyes);
    formData.append("fileexport", this.workorderForm.value.fileexport);
    formData.append("startapprover1", this.workorderForm.value.startapprover1);
    formData.append("startapprover2", this.workorderForm.value.startapprover2);
    formData.append("startapprover4", this.workorderForm.value.startapprover4);
    formData.append("startappemail1", this.workorderForm.value.startappemail1);
    formData.append("startappemail3", this.workorderForm.value.startappemail3);
    formData.append("startappemail4", this.workorderForm.value.startappemail4);
    formData.append("startappdep1", this.workorderForm.value.startappdep1);
    formData.append("startappdep2", this.workorderForm.value.startappdep2);
    formData.append("startappdep4", this.workorderForm.value.startappdep4);
    formData.append("timecardapp1", this.workorderForm.value.timecardapp1);
    formData.append("timecardapp2", this.workorderForm.value.timecardapp2);
    formData.append("timecardapp4", this.workorderForm.value.timecardapp4);
    formData.append("timecardemail1", this.workorderForm.value.timecardemail1);
    formData.append("timecardemail2", this.workorderForm.value.timecardemail2);
    formData.append("timecardemail4", this.workorderForm.value.timecardemail4);
    formData.append("timecarddep1", this.workorderForm.value.timecarddep1);
    formData.append("timecarddep2", this.workorderForm.value.timecarddep2);
    formData.append("timecarddep4", this.workorderForm.value.timecarddep4);
    formData.append("empcreateown", this.workorderForm.value.empcreateown);
    formData.append("depthead", this.workorderForm.value.depthead);
    formData.append("both", this.workorderForm.value.both);
    formData.append("Upfront", this.workorderForm.value.Upfront);
    formData.append("Accrue", this.workorderForm.value.Accrue);
    formData.append("ACAabligationyes", this.workorderForm.value.ACAabligationyes);
    // formData.append("ACAabligationno", this.workorderForm.value.ACAabligationno);
    formData.append("ACAgrpstrctyes", this.workorderForm.value.ACAgrpstrctyes);
    formData.append("nonunionvalue", this.workorderForm.value.nonunionvalue);
    formData.append("networkDistributor", this.workorderForm.value.networkDistributor);
    formData.append("deliveryDate", this.workorderForm.value.deliveryDate);
    formData.append("bondCompany", this.workorderForm.value.bondCompany);
    formData.append("financier", this.workorderForm.value.financier);
    formData.append("custyes", this.workorderForm.value.custyes);
    formData.append("custno", this.workorderForm.value.custno);
    formData.append("sickLeaveother", this.workorderForm.value.sickLeaveother);
    formData.append("homestate", this.workorderForm.value.homestate);
    formData.append("workState", this.workorderForm.value.workState);
    formData.append("files", this.filesFirst);
    formData.append("files", this.filesSecond);
    formData.append("posPay", this.workorderForm.value.posPay);
    formData.append("checkingAccount", this.workorderForm.value.checkingAccount);
    formData.append("routingAccount", this.workorderForm.value.routingAccount);
    formData.append("calSavers", this.workorderForm.value.calSavers);
    formData.append("diversity", this.workorderForm.value.diversity);
    formData.append("harassmentTraining", this.workorderForm.value.harassmentTraining);
    formData.append("projectSummary", this.workorderForm.value.projectSummary);
    formData.append("accountingsoftwarebankname", this.workorderForm.value.accountingsoftwarebankname);
    formData.append("accountingsoftwarebankaddress", this.workorderForm.value.accountingsoftwarebankaddress);
    formData.append("accountingsoftwarename", this.workorderForm.value.accountingsoftwarename);
    formData.append("accountingsoftwareemail", this.workorderForm.value.accountingsoftwareemail);
    return formData
  }
}
