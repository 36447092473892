import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { OnlineservicesComponent } from "./onlineservices/onlineservices.component";
import { workorderformComponent } from "./workorderform/workorderform.component";
import { LoginComponent } from "./login/login.component";
import { ForgetComponent } from "./forget/forget.component";
import { ResetpasswordComponent } from "./resetpassword/resetpassword.component";

import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { AdminNewsletterComponent } from "./admin/admin-newsletter/admin-newsletter.component";
import { AdminResumeComponent } from "./admin/admin-resume/admin-resume.component";
import { AuthGuard } from "./auth-guard.service";
import { AdminTruebudgetsubscribersComponent } from "./admin/admin-truebudgetsubscribers/admin-truebudgetsubscribers.component"
import { AdminPostsComponent } from "./admin/admin-posts/admin-posts/admin-posts.component";
import { AdminAddPostComponent } from "./admin/admin-posts/admin-add-post/admin-add-post.component";
import { AdminCategoriesComponent } from "./admin/admin-categories/admin-categories.component";
import { AdminCareersListComponent } from "./admin/admin-careers/admin-careers-list/admin-careers-list.component";
import { AdminTechnologyComponent } from "./admin/admin-careers/admin-technology/admin-technology.component";
import { AdminCareerResumesComponent } from "./admin/admin-careers/admin-career-resumes/admin-career-resumes.component";
import { AdminContactusComponent } from "./admin/admin-contactus/admin-contactus.component";
import { AdminDemorequestComponent } from "./admin/admin-demorequest/admin-demorequest.component";

export const appRoutes: Routes = [
  { path: "", component: OnlineservicesComponent },
  { path: "forms", component: OnlineservicesComponent },
  { path: "onlineservice/:form", component: OnlineservicesComponent },
  { path: "workorderform", component: workorderformComponent },
  { path: "login", component: LoginComponent },
  { path: "forgotpassword", component: ForgetComponent },
  { path: "reset-password", component: ResetpasswordComponent },
  {
    path: "dashboard",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "newsletter",
        component: AdminNewsletterComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "addresume",
        component: AdminResumeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "truebudget",
        component: AdminTruebudgetsubscribersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "posts",
        component: AdminPostsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "add-post",
        component: AdminAddPostComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "edit-post/:topic_id",
        component: AdminAddPostComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "categories",
        component: AdminCategoriesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "careers",
        component: AdminCareersListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "technologies",
        component: AdminTechnologyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "career-resumes",
        component: AdminCareerResumesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "contactus",
        component: AdminContactusComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "demorequests",
        component: AdminDemorequestComponent,
        canActivate: [AuthGuard],
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRouterModule {}
