import { Component, AfterViewInit, isDevMode } from "@angular/core";
import { Router, NavigationEnd } from '@angular/router';

declare let fbq:Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  onActivate(event) {
    window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }
  constructor(private router: Router){
  if (!isDevMode()) {
    router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd){
        fbq('track', 'PageView');
      }
    })
   }
  }
}
