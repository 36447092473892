import { Injectable } from "@angular/core";
import { CanActivate, Router, NavigationEnd, Event } from "@angular/router";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/pairwise";

@Injectable()
export class AuthGuard implements CanActivate {
  previousUrl: string;
  constructor(private router: Router) {}

  canActivate() {
    if (localStorage.getItem("x-token-code") != null) return true;
    else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
