import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpClientModule,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { catchError, retry } from "rxjs/operators";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable()
export class AdminService {
  constructor(public http: HttpClient) { }
  /** POST: add a new hero to the database */
  addHero(hero: any): Observable<any> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post(environment.apiUrl + "users/login", hero);
  }

  uploadresume(hero: any): Observable<any> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post(environment.apiUrl + "resumes/addResumeByAdmin", hero, {
      headers: this.getHeaders(),
    });
  }

  UploadUserform(hero: any): Observable<any> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post(environment.apiUrl + "resumes/addResume", hero);
  }

  getNews() {
    return this.http.get(environment.apiUrl + "newsletter/getNewletters");
  }

  sendDate(hero) {
    return this.http.get(
      environment.apiUrl +
      "newsletter/getNewletters/" +
      hero.startDate +
      "/" +
      hero.endDate
    );
  }
  downloadexcel(hero) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      environment.apiUrl +
      "newsletter/exportNewletters/" +
      hero.startDate +
      "/" +
      hero.endDate,
      { headers: headers, responseType: "blob" as "blob" }
    );
  }

  getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set("x-token-code", localStorage.getItem("x-token-code"));
    return headers;
  }
  getResume() {
    return this.http
      .get<any>(`${environment.apiUrl}resumes/getResumes`, {
        headers: this.getHeaders(),
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  filterByfirstname(hero) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post(environment.apiUrl + "resumes/filterResumes", hero, {
      headers: this.getHeaders(),
    });
  }

  deleteResume(data) {
    return this.http.put(`${environment.apiUrl}` + `resumes/delete`, data, {headers: this.getHeaders()})
      .pipe(map((response: any) => response));
  }
  deleteNewsletter(data) {
    return this.http.put(`${environment.apiUrl}` + `newsletter/delete`, data, {headers: this.getHeaders()})
      .pipe(map((response: any) => response));
  }

  getTrueBudgetsubscribe() {
    return this.http.get(`${environment.apiUrl}truebudget/subscribers`,{headers: this.getHeaders()})
      .map((response: Response) => response);
  }

  trueBudgetSubscribersFilter(data) {
    return this.http.get(environment.apiUrl + "truebudget/subscribers/" + data.startDate + "/" + data.endDate);
  }

  trueBudgetSubscribersExcel(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    const dates = (data.startDate && data.endDate)?`/${data.startDate}/${data.endDate}`:''
    return this.http.get(`${environment.apiUrl}truebudget/exportSubscribers${dates}`, { headers: headers, responseType: "blob" as "blob" });
  }

  deleteTrueBudgetSubscriber(data) {
    return this.http.put(`${environment.apiUrl}` + `truebudget/subscriber/delete`, data, {headers: this.getHeaders()})
      .pipe(map((response: any) => response));
  }

  getCategories() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(`${environment.apiUrl}categories`, { headers: headers });
  }

  getCategoriesList() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(`${environment.apiUrl}categories/list`, { headers: headers });
  }

  addCategory(data) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post(`${environment.apiUrl}categories/add`, data, {
      headers: this.getHeaders(),
    });
  }
  addTopic(data) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post(`${environment.apiUrl}topics/add`, data, {
      headers: this.getHeaders(),
    });
  }
  getTopic(topic_id) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.get(`${environment.apiUrl}topics/${topic_id}`, {
      headers: this.getHeaders(),
    });
  }

  updateTopic(data) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.put(`${environment.apiUrl}topics/update`, data, {
      headers: this.getHeaders(),
    });
  }

  updateCategory(data) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.put(`${environment.apiUrl}categories/update`, data, {
      headers: this.getHeaders(),
    });
  }

  deleteCategory(data) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.put(`${environment.apiUrl}categories/delete`, data, {
      headers: this.getHeaders(),
    });
  }

  deleteTopic(data) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.put(`${environment.apiUrl}topics/delete`, data, {
      headers: this.getHeaders(),
    });
  }

  getTopicsList() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.get(`${environment.apiUrl}topics`, {
      headers: this.getHeaders(),
    });
  }

  getContactForms() {
    return this.http.get(`${environment.apiUrl}supportForm/list`,{headers: this.getHeaders()})
      .map((response: Response) => response);
  }

  filterContactForms(data) {
    return this.http.get(environment.apiUrl + "supportForm/list/" + data.startDate + "/" + data.endDate);
  }

  exportContactForms(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    const dates = (data.startDate && data.endDate)?`/${data.startDate}/${data.endDate}`:''
    return this.http.get(`${environment.apiUrl}supportForm/export${dates}`, { headers: headers, responseType: "blob" as "blob" });
  }

  deleteContactForm(data) {
    return this.http.put(`${environment.apiUrl}` + `supportForm/delete`, data, {headers: this.getHeaders()})
      .pipe(map((response: any) => response));
  }

  getDemoRequests() {
    return this.http.get(`${environment.apiUrl}bookademoform/list`,{headers: this.getHeaders()})
      .map((response: Response) => response);
  }

  filterDemoRequests(data) {
    return this.http.get(environment.apiUrl + "bookademoform/list/" + data.startDate + "/" + data.endDate);
  }

  exportDemoRequests(data) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    const dates = (data.startDate && data.endDate)?`/${data.startDate}/${data.endDate}`:''
    return this.http.get(`${environment.apiUrl}bookademoform/export${dates}`, { headers: headers, responseType: "blob" as "blob" });
  }

  deleteDemoRequest(data) {
    return this.http.put(`${environment.apiUrl}` + `bookademoform/delete`, data, {headers: this.getHeaders()})
      .pipe(map((response: any) => response));
  }

}
