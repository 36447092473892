import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// modules

import { BsDropdownModule } from "ngx-bootstrap";
import { AccordionModule } from "ngx-bootstrap";
import { ModalModule, TypeaheadModule } from "ngx-bootstrap";
import { NgwWowModule } from "ngx-wow";
import { HttpModule } from "@angular/http";
import { CommonModule } from "@angular/common";
import { NgsRevealModule } from "ng-scrollreveal";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { MyDatePickerModule } from "mydatepicker";
import { Ng2TableModule } from "ng2-table/ng2-table";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { PaginationModule } from "ng2-bootstrap/pagination";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { AgGridModule } from "ag-grid-angular";
import { EditorModule } from '@tinymce/tinymce-angular';

// components
import { AppComponent } from "./app.component";
import { AppRouterModule } from "./app.route";
import { OnlineservicesComponent } from "./onlineservices/onlineservices.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { workorderformComponent } from "./workorderform/workorderform.component";
import { LoginComponent } from "./login/login.component";
import { ForgetComponent } from "./forget/forget.component";
import { ResetpasswordComponent } from "./resetpassword/resetpassword.component";
import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { AdminSidebarComponent } from "./admin-layout/admin-sidebar/admin-sidebar.component";
import { AdminNewsletterComponent } from "./admin/admin-newsletter/admin-newsletter.component";
import { AdminHeaderComponent } from "./admin-layout/admin-header/admin-header.component";
import { AdminResumeComponent } from "./admin/admin-resume/admin-resume.component";
import { AdminTruebudgetsubscribersComponent } from "./admin/admin-truebudgetsubscribers/admin-truebudgetsubscribers.component";
import { AdminPostsComponent } from "./admin/admin-posts/admin-posts/admin-posts.component";
import { AdminAddPostComponent } from "./admin/admin-posts/admin-add-post/admin-add-post.component";
import { AdminCategoriesComponent } from "./admin/admin-categories/admin-categories.component";
import { AdminCareersListComponent } from "./admin/admin-careers/admin-careers-list/admin-careers-list.component";
import { AdminTechnologyComponent } from "./admin/admin-careers/admin-technology/admin-technology.component";
import { AdminCareerResumesComponent } from "./admin/admin-careers/admin-career-resumes/admin-career-resumes.component";
import { AdminContactusComponent } from "./admin/admin-contactus/admin-contactus.component";
import { AdminDemorequestComponent } from "./admin/admin-demorequest/admin-demorequest.component";


// services

import { DataService } from "./data.service";
import { OnlineservicesmailerService } from "./onlineservices/onlineservicesmailer.service";
import { AuthGuard } from "./auth-guard.service";
import { EventEmitterService } from "./event-emitter.service";
import { InvalidControlScrollContainerDirective } from "./invalid-control-scroll-container.directive";
import { InvalidControlScrollDirective } from "./invalid-control-scroll.directive";
import { LoginService } from "./login/login.service";
import { ForgotService } from "./forget/forgot.service";
import { ResetPasswordService } from "./resetpassword/resetpassword.service";

import { AdminService } from "./admin/admin.service";
import { AdminCareerService } from "./admin/admin-careers/admin-careers.services";

@NgModule({
  declarations: [
    AppComponent,
    OnlineservicesComponent,
    HeaderComponent,
    FooterComponent,
    InvalidControlScrollDirective,
    InvalidControlScrollContainerDirective,
    workorderformComponent,
    LoginComponent,
    ForgetComponent,
    ResetpasswordComponent,
    AdminLayoutComponent,
    AdminSidebarComponent,
    AdminNewsletterComponent,
    AdminHeaderComponent,
    AdminResumeComponent,
    AdminTruebudgetsubscribersComponent,
    AdminPostsComponent,
    AdminAddPostComponent,
    AdminCategoriesComponent,
    AdminCareersListComponent,
    AdminTechnologyComponent,
    AdminCareerResumesComponent,
    AdminContactusComponent,
    AdminDemorequestComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRouterModule,
    HttpModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgsRevealModule.forRoot(),
    BrowserAnimationsModule,
    NgwWowModule.forRoot(),
    AccordionModule.forRoot(),
    SweetAlert2Module.forRoot(),
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    Ng2TableModule,
    PaginationModule.forRoot(),
    NgxPaginationModule,
    Ng2SearchPipeModule,
    AgGridModule.withComponents([
      AdminNewsletterComponent,
      AdminTruebudgetsubscribersComponent
    ]),
    TypeaheadModule.forRoot(),
    EditorModule
  ],
  providers: [
    AuthGuard,
    DataService,
    OnlineservicesmailerService,
    EventEmitterService,
    LoginService,
    ForgotService,
    ResetPasswordService,
    AuthGuard,
    AdminService,
    ResetPasswordService,
    ForgotService,
    DataService,
    OnlineservicesmailerService,
    LoginService,
    EventEmitterService,
    AdminCareerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
