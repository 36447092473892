import { Component, OnInit } from "@angular/core";
import { AdminService } from "../admin.service";
import * as moment from "moment";
import * as FileSaver from "file-saver";

declare var $: any;

@Component({
  selector: "app-admin-newsletter",
  templateUrl: "./admin-newsletter.component.html",
  styleUrls: ["./admin-newsletter.component.css"],
})
export class AdminNewsletterComponent implements OnInit {
  items = [];
  minDate: Date;
  maxDate: Date;
  data: Date;
  selectDate = {
    startDate: "",
    endDate: "",
  };
  filterargs;
  tableData = [];
  p: number = 1;
  FIRST_NAME;
  LAST_NAME;
  EMAIL_ID;
  MOBILE_NUMBER;
  CREATED_DATE;
  searchText;
  nodata: boolean = false;
  headers = [
    "S.NO",
    "FIRST NAME",
    "LAST NAME",
    "EMAIL ID",
    "PHONE",
    "CREATED DATE",
  ];
  icons = {
    filter: " ",
  };
  columnDefs = [
    {
      headerName: "FIRST NAME",
      field: "FIRST_NAME",
      sortable: true,
      filter: true,
      width: 200,
      resizable: true
    },
    {
      headerName: "LAST NAME",
      field: "LAST_NAME",
      sortable: true,
      filter: true,
      width: 200,
      resizable: true
    },
    {
      headerName: "EMAIL ID",
      field: "EMAIL_ID",
      sortable: true,
      filter: true,
      width: 250,
      resizable: true
    },
    {
      headerName: "PHONE",
      field: "MOBILE_NUMBER",
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: "SUBSCRIBED DATE",
      field: "CREATED_DATE",
      sortable: true,
      filter: true,
      resizable: true
    },
    {
      headerName: "ACTIONS",
      sortable: false,
      filter: false,
      cellRenderer: function (params) {
        return (
          `<span><i class="fa fa-trash" aria-hidden="true"></i></span>`
        );
      },
      width: 100,
    },
  ];
  deleteNewsletterId:any;
  responseMessage: string;

  constructor(public service: AdminService) {}

  ngOnInit() {
    this.service.getNews().subscribe((res: any) => {
      this.tableData = res.data;
      this.createdIndex();
    });
  }
  onValueChange(value: Date): void {
    if (value) {
      const firstdate = value[0];
      const seconddate = value[1];
      var event = new Date(firstdate);
      var eventone = new Date(seconddate);
      let date = JSON.stringify(event);
      let datesecond = JSON.stringify(eventone);
      this.selectDate.startDate = date.slice(1, 11);
      this.selectDate.endDate = datesecond.slice(1, 11);
      this.service.sendDate(this.selectDate).subscribe((res: any) => {
        this.tableData = res.data;
        this.createdIndex();
      });
    }
  }
  downloadexcel() {
    this.service.downloadexcel(this.selectDate).subscribe(
      (res) => {
        const file = new File([res], "report.xlsx", {
          type: "application/vnd.ms-excel",
        });
        FileSaver.saveAs(file);
      },
      (error) => {
        console.log(error.message);
      }
    );
  }
  createdIndex() {
    this.tableData.forEach((row, i) => (row["s_no"] = i + 1));
  }
  onCellClicked(event){
    if(event.colDef.headerName == 'ACTIONS'){
      this.deleteNewsletterId = event.data.NEWSLETTER_ID
      $("#deleteModal").modal("show");
    }
  }
  deleteConfirm(){
    this.responseMessage = '';
    this.service.deleteNewsletter({ID:this.deleteNewsletterId}).subscribe(
      (response) => {
        this.responseMessage = response.message;
        setTimeout(function () {
          window.location.reload();
          $("#deleteModal").modal("hide");
        }, 3000);
      },(error)=>{
        this.responseMessage = error.error.message;
      })
  }
}
