import { Component, OnInit } from '@angular/core';
import { AdminCareerService } from "../admin-careers.services";
import * as moment from "moment";
declare var $: any;

@Component({
  selector: 'app-admin-career-resumes',
  templateUrl: './admin-career-resumes.component.html',
  styleUrls: ['./admin-career-resumes.component.css']
})
export class AdminCareerResumesComponent implements OnInit {
  
  items = [];
  tableData: any[] = [];
  icons = {
    filter: " ",
  };
  deleteResumeId:any;
  responseMessage:any;

  columnDefs = [
    {
      headerName: "FIRST NAME",
      field: "first_name",
      sortable: true,
      filter: true,
      width: 150,
      resizable: true
    },
    {
      headerName: "LAST NAME",
      field: "last_name",
      sortable: true,
      filter: true,
      width: 150,
      resizable: true
    },
    {
      headerName: "EMAIL ID",
      field: "email_id",
      sortable: true,
      filter: true,
      width: 250,
      resizable: true
    },
    {
      headerName: "MOBILE NUMBER",
      field: "mobile_number",
      sortable: true,
      filter: true,
      width: 180,
      resizable: true
    },
    {
      headerName: "POSITION",
      field: "position",
      sortable: true,
      filter: true,
      width: 140,
      resizable: true
    }, 
    {
      headerName: "APPLIED DATE",
      field: "created_date",
      suppressFilter: false,
      width: 115,
      resizable: true
    },
    {
      headerName: "UPDATED DATE",
      field: "updated_date",
      suppressFilter: false,
      width: 115,
      resizable: true
    },
    {
      headerName: "DOWNLOAD",
      field: "preSignedURL",
      sortable: true,
      filter: false,
      cellRenderer: function (params) {
        return (
          "<span><a href=" + params.value + ' rel="noopener"> <i class="fa fa-download download-icon" aria-hidden="true"></i></a></span>'
        );
      },
      width: 100,
      cellStyle: {'display': 'flex', 'justify-content': 'center', 'align-items':'center', },
      resizable: true
    },
    {
      headerName: "ACTIONS",
      sortable: false,
      filter: false,
      cellRenderer: function (params) {
        return (
          `<span><i class="fa fa-trash" aria-hidden="true"></i></span>`
        );
      },
      width: 80,
      cellStyle: {'display': 'flex', 'justify-content': 'center', 'align-items':'center'}
    },
  ];
  constructor(private service: AdminCareerService) { }

  ngOnInit() {

    this.service.getCareerResumes().subscribe((res: any) => {
      this.tableData = res.data;
    });
  }

  download(item) {
    window.open(item.preSignedURL);
  }

  onCellClicked(event){
    if(event.colDef.headerName == 'ACTIONS'){
      this.deleteResumeId = event.data.career_resume_id
      $("#deleteModal").modal("show");
    }
  }

  deleteConfirm(){
    this.responseMessage = '';
    this.service.deleteResume({career_resume_id:this.deleteResumeId}).subscribe(
      (response) => {
        this.responseMessage = response.message;
        setTimeout(function () {
          $("#deleteModal").modal("hide");
          this.responseMessage = '';
            }, 3000);
      },(error)=>{
        this.responseMessage = error.error.message;
      })
  }
}

