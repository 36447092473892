import { Component, OnInit } from '@angular/core';
import { AdminCareerService } from "../admin-careers.services";
import * as moment from "moment";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-admin-technology',
  templateUrl: './admin-technology.component.html',
  styleUrls: ['./admin-technology.component.css']
})
export class AdminTechnologyComponent implements OnInit {

  technologies:any = [];
  icons = {
    filter: " ",
  };
  columnDefs = [];
  technologyForm: FormGroup;
  technologySubmitted = false;
  button = 'Save';
  displaymessage: any;
  successtoast: any;
  showSuccessToast = false;
  type = 'add';
  deleteTechnologyId:any;
  popupHeading:any;
  popupMessage:any;
  popupButton:any;
  deleteResponseMessage:any;
  editTechnology:any;

  constructor(private apiCall:AdminCareerService) { }

  ngOnInit() {
    this.technologyForm = new FormGroup({
      technology_name: new FormControl("", [Validators.required]),
    });
    this.getList()
  }

  getList(){
    this.apiCall.getTechnologies().subscribe((res:any) => {
      this.technologies = res.data;
      this.getcolumnDefs()
    })
  }

  onClickButton(params) {
    const eDiv = document.createElement("div");
    const self = this;
    eDiv.innerHTML = `<span class="my-css-class">
    <button title="Update a Technology" class="btn btn-sm ml-1 mt-2 mb-2 btn-edit"><i class="fa fa-edit" aria-hidden="true"></i></button>
    <button title="Delete a Technology" class="btn btn-sm ml-1 mt-2 mb-2 btn-delete"><i class="fa fa-trash" aria-hidden="true"></i></button>
    </span>`;

    var editButton = eDiv.querySelectorAll(".btn-edit")[0];
    editButton.addEventListener("click", function () {
      console.log('params.data',params.data)
      self.type = 'edit';
      self.editTechnology = params.data;
      self.technologyForm.setValue({technology_name:params.data.technology_name})
      $("#technologyModal").modal("show");
      });
    
      var deleteButton = eDiv.querySelectorAll(".btn-delete")[0];
      deleteButton.addEventListener("click", function () {
          self.deleteTechnologyId = params.data.technology_id;
          self.deleteResponseMessage = '';
          self.popupHeading = 'Delete';
          self.popupMessage = 'Do you want to delete?';
          self.popupButton = 'Confirm';
          $("#deleteModal").modal("show");
        });

    return eDiv;
  }

  getcolumnDefs(){
    this.columnDefs = [
      {
        headerName: "TECHNOLOGY TITLE",
        field: "technology_name",
        sortable: true,
        filter: true,
        width: 450,
        resizable: true
      },
      {
        headerName: "CREATED DATE",
        valueGetter: (params) => {
          return moment(params.data.created_date).format("MM-DD-YYYY")
        },
        sortable: true,
        filter: true,
        width: 150,
        resizable: true
      },
      {
        headerName: "UPDATED DATE",
        valueGetter: (params) => {
          return moment(params.data.updated_date).format("MM-DD-YYYY")
        },
        sortable: true,
        filter: true,
        width: 150,
        resizable: true
      },
      {
        headerName: "ACTIONS",
        sortable: false,
        filter: false,
        cellRenderer: this.onClickButton.bind(this),
        width: 150,
      },
    ]
  }

  onSubmitTechnology(){
    this.technologySubmitted = true;
    this.displaymessage = '';

    if (this.technologyForm.invalid) {
      return;
    }

    this.button = 'Saving...';
    if(this.type == 'add'){
      this.apiCall.addTechnology(this.technologyForm.value).subscribe((res:any) => {
        this.updateResponse(res);
      },(error) => {
        this.displaymessage = error.error.message;
        })
    }else{
      this.technologyForm.value.technology_id = this.editTechnology.technology_id;
      this.apiCall.updateTechnology(this.technologyForm.value).subscribe((res:any) => {
        this.updateResponse(res);
      },(error) => {
        this.displaymessage = error.error.message;
        })
    }
  }

  closeModal(){
    $("#technologyModal").modal("hide");
    this.technologyForm.reset();
    this.technologySubmitted = false;
  }
  updateResponse(res){
    this.successtoast = res.message;
    this.showSuccessToast = true;  
    this.button = 'Save';
    this.technologyForm.reset();
    this.technologySubmitted = false;
    this.getList();
    setTimeout(() => {
    this.successtoast = '';
    this.showSuccessToast = false;
    $("#technologyModal").modal("hide");
    }, 3000);
  }

  deleteTechnology(){

    this.apiCall.deleteTechnology({technology_id:this.deleteTechnologyId}).subscribe((res:any) => {
      this.deleteResponseMessage = res.message;
      this.getList()
      setTimeout(function () {
        $("#deleteModal").modal("hide");
        this.deleteResponseMessage = '';
      }, 3000);
    },(error) => {
      this.deleteResponseMessage = error.error.message;
    })
    
  }
}
