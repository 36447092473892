import { Component, OnInit } from "@angular/core";
import { ResetPasswordService } from "./resetpassword.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-resetpassword",
  templateUrl: "./resetpassword.component.html",
  styleUrls: ["./resetpassword.component.css"],
})
export class ResetpasswordComponent implements OnInit {
  resetpasswordForm: FormGroup;
  loader = false;
  submitted = false;
  pwdmessage: string;
  submittitle = "Change Password";
  username: string;
  password: any;
  passwordUpdated: any;
  notequalPassword: string;
  notequalPassworderror: boolean;
  successToast: boolean;
  displayerrormessage: boolean;
  displaymessage: any;
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    public service: ResetPasswordService
  ) {}

  ngOnInit() {
    this.resetpasswordForm = this.formBuilder.group({
      username: new FormControl(localStorage.getItem("username")),
      password: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,]).{6,30}$/
          ),
        ],
      ],
      confirmPassword: [null, [Validators.required]],
    });
  }
  get f() {
    return this.resetpasswordForm.controls;
  }
  onSubmit() {
    this.pwdmessage = "";
    this.submitted = true;
    this.notequalPassworderror = false;
    this.successToast = false;
    this.submittitle = "Changing Password...";
    if (
      this.resetpasswordForm.value.password !=
      this.resetpasswordForm.value.confirmPassword
    ) {
      this.notequalPassworderror = true;
      this.notequalPassword =
        "The password, and confirmation password do not match.";
      this.submittitle = "Change Password";
    } else {
      if (
        this.resetpasswordForm.value.password ==
        this.resetpasswordForm.value.confirmPassword
      ) {
        this.submitted = false;
        this.service.addHero(this.resetpasswordForm.value).subscribe(
          (res) => {
            if (res.success) {
              this.successToast = true;
              this.passwordUpdated = res.message;
              this.resetpasswordForm.reset();
              setTimeout(() => {
                this.router.navigate(["/login"], { replaceUrl: true });
                this.submittitle = "Change Password";
              }, 3000);
            }
          },
          (error) => {
            if (!error.error.success) {
              this.displayerrormessage = true;
              this.displaymessage = error.error.message;
              this.submittitle = "Change Password";
              this.resetpasswordForm.reset();
            }
          }
        );
      }
    }
  }
  //   onSubmit() {
  //     if(this.loginForm.valid) {
  //       this.successToast = false;
  //       this.service.addHero(this.loginForm.value).subscribe( (res: any) => {
  //        this.successToast = true;
  //        this.successtoast = res.message;
  //        if(res.message === "Updated successfully"){
  //         setTimeout((res:any) => {
  //             this.router.navigate(['./login']);
  //          }, 1000);
  //        }
  //     });
  //   }
  // }
}
