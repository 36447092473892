import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { AdminService } from "../admin.service";
import * as moment from "moment";

declare var $: any;

@Component({
  selector: 'app-admin-categories',
  templateUrl: './admin-categories.component.html',
  styleUrls: ['./admin-categories.component.css']
})
export class AdminCategoriesComponent implements OnInit {

  categoryForm: FormGroup;
  categorySubmitted = false;
  button = 'Save';
  selectedCategory:any;
  categoryList = [];
  categories = [];
  file: any;
  displaymessage: any;
  successtoast: any;
  categoryImage:any;
  categoryType = 'add';
  editCategoryId:any;
  deleteCategoryId:any;
  popupHeading:any;
  popupMessage:any;
  popupButton:any;
  deleteResponseMessage:any;
  deleteItem:any;
  imageFile: any;
  columnDefs:any = [];
  viewDescription:any = {}
  icons = {
    filter: " ",
  };
  showSuccessToast = false;
  constructor(public service: AdminService) { }

  ngOnInit() {
    this.categoryForm = new FormGroup({
      category_name: new FormControl("", [Validators.required]),
      category_content: new FormControl("", [Validators.required]),
      file: new FormControl(this.file, [Validators.required]),
    });
    this.loadData()

  }

  fileBrowseHandler(files) {
    this.file = files.item(0);
  }

  onSubmitCategory(){

    this.categorySubmitted = true;
    this.displaymessage = '';
    let name

    if(this.categoryType != 'add' && this.categoryImage){
      this.categoryForm.get("file").setErrors(null);//updating error message
      this.categoryForm.updateValueAndValidity();//update validation
    }
    if (this.categoryForm.invalid) {
      return;
    }

     if(!this.categoryImage){
       
      name = this.file.name.split(".").pop();
      name = name.toLowerCase();
      if (name != 'png' && name != 'jpg' && name != 'jpeg' ) {
        this.displaymessage = "The selected file is not supported. Please select the file format IMG";
        return;
      }
    }

    let form = new FormData();
    form.append("category_name", this.categoryForm.value.category_name);
    form.append("category_content", this.categoryForm.value.category_content);
    form.append("file", this.file);
      this.button = 'Saving...';
      if(this.categoryType == 'add'){
        this.service.addCategory(form).subscribe((res:any) => {
          this.successtoast = res.message;
          this.showSuccessToast = true;
          this.button = 'Save';
          this.categoryForm.reset();
          this.categorySubmitted = false;
          this.loadData();
          setTimeout(() => {
          this.successtoast = '';
          this.showSuccessToast = false;
          }, 3000);
        },(error) => {
        this.displaymessage = error.error.message;
        })
      }else{
        form.append("category_id", this.editCategoryId);
        this.service.updateCategory(form).subscribe((res:any) => {
          this.successtoast = res.message;
          this.showSuccessToast = true;
          this.button = 'Save';
          this.categoryForm.reset();
          this.categorySubmitted = false;
          this.loadData()
          this.categoryType = 'add';
          this.imageFile = '';
          setTimeout(() => {
          this.successtoast = '';
          this.showSuccessToast = false;
          }, 3000);
        },(error) => {
        this.displaymessage = error.error.message;
        })
      }
  }

  getCategories(){
    this.service.getCategoriesList().subscribe((res: any) => {
      this.categoryList = res.data
      this.getcolumnDefs();
    });
  }

  editCategory(category){
    this.categoryImage = '';
    this.categoryType = 'edit';
    this.imageFile = category.category_image?category.category_image.split("/").pop():'';
    let obj = {
      category_name: category.category_name,
      category_content: category.category_content,
      file:''
    }
    this.categoryImage = category.category_image_url;
    this.editCategoryId = category.category_id;
    this.categoryForm.setValue(obj);
  }

  
  deleteCategory(){

    this.service.deleteCategory({category_id:this.deleteCategoryId}).subscribe((res:any) => {
      this.deleteResponseMessage = res.message;
      this.loadData()
      setTimeout(function () {
        $("#deleteModal").modal("hide");
        this.deleteResponseMessage = '';
      }, 3000);
    },(error) => {
      this.deleteResponseMessage = error.error.message;
    })
    
  }

  loadData(){
    this.getCategories()
  }

  onClickButton(params) {
    const eDiv = document.createElement("div");
    const self = this;
    eDiv.innerHTML = `<span class="my-css-class">
    <button title="Update a Category" class="btn btn-sm ml-1 mt-2 mb-2 btn-edit"><i class="fa fa-edit" aria-hidden="true"></i></button>
    <button title="Delete a Category" class="btn btn-sm ml-1 mt-2 mb-2 btn-delete"><i class="fa fa-trash" aria-hidden="true"></i></button>
    </span>`;

    var editButton = eDiv.querySelectorAll(".btn-edit")[0];
    editButton.addEventListener("click", function () {
        self.editCategory(params.data);
      });
    
      var deleteButton = eDiv.querySelectorAll(".btn-delete")[0];
      deleteButton.addEventListener("click", function () {
          self.deleteCategoryId = params.data.category_id;
          self.deleteResponseMessage = '';
          self.popupHeading = 'Delete';
          self.popupMessage = 'Do you want to delete?';
          self.popupButton = 'Confirm';
          $("#deleteModal").modal("show");
        });
    return eDiv;
  }
  onClickDescription(params) {
    const eDiv = document.createElement("div");
    const self = this;
    eDiv.innerHTML = `<span class="category-css-class">
    <button title="View a Descrition" class="btn btn-sm ml-1 mt-2 mb-2 view">View</button>
    </span>`;

    var editButton = eDiv.querySelectorAll(".view")[0];
    editButton.addEventListener("click", function () {
      self.viewDescription = params.data;
      $("#viewModal").modal("show");
      });
    return eDiv;
  }

  
  getcolumnDefs(){
    this.columnDefs = [
      {
        headerName: "CATEGORY NAME",
        field: "category_name",
        sortable: true,
        filter: true,
        width: 240,
        resizable: true
      },
      {
        headerName: "DESCRIPTION",
        cellRenderer: this.onClickDescription.bind(this),
        sortable: true,
        filter: true,
        width: 130,
        resizable: true
      },
      {
        headerName: 'COUNT',
        valueGetter: (params) => {

          return params.data.topics?params.data.topics.length:0
        
        },
        sortable: true,
        filter: true,
        width: 100,
        resizable: true
      },
      {
        headerName: "PUBLISHED DATE",
        valueGetter: (params) => {
          return moment(params.data.created_date).format("MM-DD-YYYY")
        },
        sortable: true,
        filter: true,
        width: 150,
        resizable: true
      },
      {
        headerName: "UPDATED DATE",
        valueGetter: (params) => {
          return moment(params.data.updated_date).format("MM-DD-YYYY")
        },
        sortable: true,
        filter: true,
        width: 130,
        resizable: true
      },
      {
        headerName: "ACTIONS",
        sortable: false,
        filter: false,
        cellRenderer: this.onClickButton.bind(this),
        cellStyle: { "padding": 0},
        width: 100,
      },
    ]
  }


}
