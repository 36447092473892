import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable()
export class ResetPasswordService {
  constructor(public http: HttpClient) {}

  /** POST: add a new hero to the database */
  addHero(hero: any): Observable<any> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http
      .post<any>(environment.apiUrl + "users/reset-password", hero)
      .pipe(map((res: Response) => res));
  }
}
