import { Component, OnInit } from "@angular/core";
import { LoginService } from "./login.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  displayerrormessage: boolean;
  loginForm: FormGroup;
  successtoast: any;
  successToast: boolean;
  displaymessage: any;
  displayerroraccess: boolean;
  submititle = "Submit";
  loginfromsubmitted = false;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    public service: LoginService
  ) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ]),
      password: new FormControl("", [Validators.required]),
    });
  }
  get primEmail() {
    return this.loginForm.get("username");
  }
  onSubmit() {
    this.loginfromsubmitted = true;
    // using api
    if (this.loginForm.invalid) {
      return;
    }
    this.successToast = false;
    this.displayerrormessage = false;
    this.submititle = "Submitting ...";
    this.service.addHero(this.loginForm.value).subscribe(
      (res) => {
        this.successToast = true;
        this.successtoast = res.message;
        this.displayerrormessage = false;
        this.submititle = "Submit";
        if (res.success) {
          const username = this.loginForm.value.username;
          localStorage.setItem("username", username);
          const token = res.data.token;
          this.submititle = "Submit";
          localStorage.setItem("x-token-code", token);
          setTimeout((res: any) => {
            this.router.navigate(["./dashboard/newsletter"]);
          }, 1000);
        }
      },
      (error) => {
        if (!error.error.success) {
          this.submititle = "Submit";
          this.displayerrormessage = true;
          this.displaymessage = error.error.message;
          this.loginForm.reset();
          this.loginfromsubmitted = false;
        }
      }
    );
  }
}
