// tslint:disable-next-line:class-name

export const Keywords = [
  { pagenavid: "payroll", keyword: "payroll" },
  { pagenavid: "payroll", keyword: "Television" },
  { pagenavid: "payroll", keyword: "Features" },
  { pagenavid: "payroll", keyword: "Commercials" },
  { pagenavid: "payroll", keyword: "Music Videos" },
  { pagenavid: "payroll", keyword: "Live events" },
  { pagenavid: "payroll", keyword: "Reality" },
  { pagenavid: "payroll", keyword: "Documentary" },
  { pagenavid: "payroll", keyword: "Scripted" },
  { pagenavid: "payroll", keyword: "Game show" },
  { pagenavid: "payroll", keyword: "Episodic" },
  { pagenavid: "payroll", keyword: "Sports" },
  { pagenavid: "payroll", keyword: "MOW" },
  { pagenavid: "payroll", keyword: "Live" },
  { pagenavid: "payroll", keyword: "Independents" },
  { pagenavid: "payroll", keyword: "Short-form" },
  { pagenavid: "payroll", keyword: "New media" },
  { pagenavid: "payroll", keyword: "Radio" },
  { pagenavid: "payroll", keyword: "Industrial" },
  { pagenavid: "payroll", keyword: "Long-form" },
  { pagenavid: "payroll", keyword: "Narrative" },
  { pagenavid: "payroll", keyword: "Concerts" },
  { pagenavid: "payroll", keyword: "Stand-Up Comedy" },
  { pagenavid: "payroll", keyword: "Theatre" },

  { pagenavid: "software", keyword: "ProHire" },
  { pagenavid: "software", keyword: "ProCal" },
  { pagenavid: "software", keyword: "ProAnalytics" },
  { pagenavid: "software", keyword: "ProAnalytics AI" },
  { pagenavid: "software", keyword: "Timecards" },
  { pagenavid: "software", keyword: "Digital" },
  { pagenavid: "software", keyword: "Calendar" },
  { pagenavid: "software", keyword: "Payroll data" },
  { pagenavid: "software", keyword: "Affordable Care Act" },
  { pagenavid: "software", keyword: "AI" },
  { pagenavid: "software", keyword: "Software" },
  { pagenavid: "software", keyword: "Production accounting" },
  { pagenavid: "software", keyword: "Start forms" },
  { pagenavid: "software", keyword: "Hours-to-Gross" },
  { pagenavid: "software", keyword: "HTG" },

  { pagenavid: "healthcare", keyword: "Healthcare" },
  { pagenavid: "healthcare", keyword: "Benefits" },
  { pagenavid: "healthcare", keyword: "Insurance" },
  { pagenavid: "healthcare", keyword: "Medical" },
  { pagenavid: "healthcare", keyword: "Dental" },
  { pagenavid: "healthcare", keyword: "Vision" },
  { pagenavid: "healthcare", keyword: "Administration" },
  { pagenavid: "healthcare", keyword: "Employee Onboarding" },
  { pagenavid: "healthcare", keyword: "Payroll Deductions" },
  { pagenavid: "healthcare", keyword: "Direct Billing" },
  { pagenavid: "healthcare", keyword: "Waivers" },
  { pagenavid: "healthcare", keyword: "COBRA" },
  { pagenavid: "healthcare", keyword: "Annual Reporting" },
  { pagenavid: "healthcare", keyword: "Disclosure" },
  { pagenavid: "healthcare", keyword: "HIPPA" },
  { pagenavid: "healthcare", keyword: "ACA" },
  { pagenavid: "healthcare", keyword: "ERISA" },
  { pagenavid: "healthcare", keyword: "DOL" },
  { pagenavid: "healthcare", keyword: "5500s" },
  { pagenavid: "healthcare", keyword: "SPD" },
  { pagenavid: "healthcare", keyword: "Wrap Documents" },
  { pagenavid: "healthcare", keyword: "SMM" },
  { pagenavid: "healthcare", keyword: "SBC" },
  { pagenavid: "healthcare", keyword: "5500 Reporting" },
  { pagenavid: "healthcare", keyword: "ACA Compliance" },
  { pagenavid: "healthcare", keyword: "Employee Eligibility" },
  { pagenavid: "healthcare", keyword: "1094-C" },
  { pagenavid: "healthcare", keyword: "1095-C" },
  { pagenavid: "healthcare", keyword: "IRS Reporting" },
  { pagenavid: "healthcare", keyword: "Employer Size Analysis" },
  { pagenavid: "taxincentive", keyword: "Tax Incentives" },

  { pagenavid: "staffing", keyword: "PriorityWorkforce" },
  { pagenavid: "staffing", keyword: "Commercial Staffing" },
  { pagenavid: "staffing", keyword: "Industries" },
  { pagenavid: "staffing", keyword: "Manufacturing" },
  { pagenavid: "staffing", keyword: "Food and Beverage" },
  { pagenavid: "staffing", keyword: "Warehousing" },
  { pagenavid: "staffing", keyword: "Logistics" },
  { pagenavid: "staffing", keyword: "business services" },

  { pagenavid: "support", keyword: "Contact" },
  { pagenavid: "support", keyword: "Office" },
  { pagenavid: "support", keyword: "Locations" },
  { pagenavid: "support", keyword: "Forms" },
  { pagenavid: "support", keyword: "Supplies" },
  { pagenavid: "support", keyword: "WC" },
  { pagenavid: "support", keyword: "Workers Comp" },
  { pagenavid: "support", keyword: "support" },

  { pagenavid: "aboutus", keyword: "aboutus" },

  { pagenavid: "partnersandaffiliates", keyword: "partnersandaffiliates" },

  { pagenavid: "onlineservices", keyword: "onlineservices" },
  { pagenavid: "onlineservices", keyword: "client work order form" },
  { pagenavid: "onlineservices", keyword: "supplies" },
  { pagenavid: "onlineservices", keyword: "change of address" },
  { pagenavid: "onlineservices", keyword: "signup for direct deposit" },
  { pagenavid: "onlineservices", keyword: "contract service letter" },
  { pagenavid: "onlineservices", keyword: "lost/stolen/missing checks" },
  { pagenavid: "onlineservices", keyword: "state tax withholding" },
  { pagenavid: "onlineservices", keyword: "workers comp injury report" },
];
