import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import "rxjs/add/operator/map";
import { environment } from "../../environments/environment";

@Injectable()
export class OnlineservicesmailerService {
  bufferresponse: string;
  constructor(private _http: Http) { }
  emailService(newvalue: any) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this._http
      .post(environment.apiUrl + "onlineServiceRoute/changeOfAdresses", newvalue)
      .map((response: Response) => response.json());
  }
  SignupDD(newvalue: any) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this._http
      .post(environment.apiUrl + "onlineServiceRoute/SignupDD", newvalue)
      .map((response: Response) => response.json());
  }

  contractServiceFrom(newvalue: any) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this._http
      .post(environment.apiUrl + "onlineServiceRoute/contractServiceLetter", newvalue)
      .map((response: Response) => response.json());
  }
  lostStolenChecks(newvalue: any) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this._http
      .post(environment.apiUrl + "onlineServiceRoute/lostStolenChecks", newvalue)
      .map((response: Response) => response.json());
  }
  requestW2(newvalue: any) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this._http
      .post(environment.apiUrl + "onlineServiceRoute/requestW2", newvalue)
      .map((response: Response) => response.json());
  }
  workorderForm(newvalue: any) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this._http
      .post(environment.apiUrl + "workOrderfrom", newvalue)
      .map((response: Response) => response);
  }
  suppliesForm(newvalue: any) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this._http
      .post(environment.apiUrl + "onlineServiceRoute/suppliesForm", newvalue)
      .map((response: Response) => response);
  }
  injuryreportForm(newvalue: any) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this._http
      .post(environment.apiUrl + "injuryreportform", newvalue)
      .map((response: Response) => response);
  }
  payrollSubmission(data: any) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this._http
      .post(environment.apiUrl + "payrollSubmissionform", data)
      .map((response: Response) => response);
  }
}
