import { Component, OnInit } from '@angular/core';
import { AdminCareerService } from "../admin-careers.services";
import * as moment from "moment";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-admin-careers-list',
  templateUrl: './admin-careers-list.component.html',
  styleUrls: ['./admin-careers-list.component.css']
})
export class AdminCareersListComponent implements OnInit {

  constructor(private apiCall:AdminCareerService) { }

  careers:any = [];
  icons = {
    filter: " ",
  };
  columnDefs = [];
  careerForm: FormGroup;
  careerSubmitted = false;
  button = 'Save';
  displaymessage: any;
  successtoast: any;
  showSuccessToast = false;
  type = 'add';
  deleteCareerId:any;
  popupHeading:any;
  popupMessage:any;
  popupButton:any;
  deleteResponseMessage:any;
  selectedCareer:any;
  viewDescription:any = {};
  jobs:any = [];

  ngOnInit() {
    this.careerForm = new FormGroup({
      job_title: new FormControl("", [Validators.required]),
      years_of_experience: new FormControl("", [Validators.required]),
      job_location: new FormControl("", [Validators.required]),
      job_description: new FormControl("", [Validators.required])
    });
    this.getList()
    this.getTechnologies()
  }

  getList(){
    this.apiCall.getCareers().subscribe((res:any) => {
      this.careers = res.data;
      this.getColumnDefs()
    })
  }

  getTechnologies(){
    this.apiCall.getTechnologies().subscribe((res:any) => {
      this.jobs = res.data;
    })
  }
  onClickButton(params) {
    const eDiv = document.createElement("div");
    const self = this;
    eDiv.innerHTML = `<span class="my-css-class">
    <button title="Update a career" class="btn btn-sm ml-1 mt-2 mb-2 btn-edit"><i class="fa fa-edit" aria-hidden="true"></i></button>
    <button title="Delete a career" class="btn btn-sm ml-1 mt-2 mb-2 btn-delete"><i class="fa fa-trash" aria-hidden="true"></i></button>
    </span>`;

    var editButton = eDiv.querySelectorAll(".btn-edit")[0];
    editButton.addEventListener("click", function () {
      self.editCareer(params.data)
      });
    
      var deleteButton = eDiv.querySelectorAll(".btn-delete")[0];
      deleteButton.addEventListener("click", function () {
          self.deleteCareerId = params.data.career_id;
          self.deleteResponseMessage = '';
          self.popupHeading = 'Delete';
          self.popupMessage = 'Do you want to delete?';
          self.popupButton = 'Confirm';
          $("#deleteModal").modal("show");
        });

    return eDiv;
  }

  onClickDescription(params) {
    const eDiv = document.createElement("div");
    const self = this;
    eDiv.innerHTML = `<span class="category-css-class">
    <button title="View a Descrition" class="btn btn-sm ml-1 mt-2 mb-2 view">View</button>
    </span>`;

    var editButton = eDiv.querySelectorAll(".view")[0];
    editButton.addEventListener("click", function () {
      self.viewDescription = params.data;
      $("#viewModal").modal("show");
      });
    return eDiv;
  }

  getColumnDefs(){
    this.columnDefs = [
      {
        headerName: "JOB TITLE",
        field: "job_title",
        sortable: true,
        filter: true,
        width: 350,
        resizable: true
      },
      {
        headerName: "JOB DESCRIPTION",
        cellRenderer: this.onClickDescription.bind(this),
        sortable: true,
        filter: true,
        width: 150,
        resizable: true
      },
      {
        headerName: "YEARS OF EXPERIENCE",
        field: "years_of_experience",
        sortable: true,
        filter: true,
        width: 180,
        resizable: true
      },
      {
        headerName: "JOB LOCATION",
        field: "job_location",
        sortable: true,
        filter: true,
        width: 180,
        resizable: true
      },
      {
        headerName: "CREATED DATE",
        valueGetter: (params) => {
          return moment(params.data.created_date).format("MM-DD-YYYY")
        },
        sortable: true,
        filter: true,
        width: 130,
        resizable: true
      },
      {
        headerName: "UPDATED DATE",
        valueGetter: (params) => {
          return params.data.updated_date?moment(params.data.updated_date).format("MM-DD-YYYY"):''
        },
        sortable: true,
        filter: true,
        width: 130,
        resizable: true
      },
      {
        headerName: "ACTIONS",
        sortable: false,
        filter: false,
        cellRenderer: this.onClickButton.bind(this),
        width: 130,
      },
    ]
  }

  onSubmitCareer(){
    this.careerSubmitted = true;
    this.displaymessage = '';

    if (this.careerForm.invalid) {
      return;
    }

    this.button = 'Saving...';
    if(this.type == 'add'){
      this.apiCall.addCareer(this.careerForm.value).subscribe((res:any) => {
        this.updateResponse(res);
      },(error) => {
        this.displaymessage = error.error.message;
        })
    }else{
      this.careerForm.value.career_id = this.selectedCareer.career_id;
      this.apiCall.updateCareer(this.careerForm.value).subscribe((res:any) => {
        this.updateResponse(res);
      },(error) => {
        this.displaymessage = error.error.message;
        })
    }
  }

  closeModal(){
    $("#careerModal").modal("hide");
    this.careerSubmitted = false;
    this.careerForm.reset();
  }

  updateResponse(res){
    this.successtoast = res.message;
    this.showSuccessToast = true;  
    this.button = 'Save';
    this.careerForm.reset();
    this.type = 'add';
    this.getList();
    this.careerSubmitted = false;
    setTimeout(() => {
    this.successtoast = '';
    this.showSuccessToast = false;
    $("#careerModal").modal("hide");
    }, 3000);
  }

  deleteCareer(){

    this.apiCall.deleteCareer({career_id:this.deleteCareerId}).subscribe((res:any) => {
      this.deleteResponseMessage = res.message;
      this.getList()
      setTimeout(function () {
        $("#deleteModal").modal("hide");
        this.deleteResponseMessage = '';
      }, 3000);
    },(error) => {
      this.deleteResponseMessage = error.error.message;
    })
    
  }

  editCareer(career){
    this.type = 'edit';
    this.selectedCareer = career;
    let obj = {
      job_title: career.job_title,
      years_of_experience: career.years_of_experience,
      job_location: career.job_location,
      job_description: career.job_description
    }
    this.careerForm.setValue(obj)
    $("#careerModal").modal("show");
  }
}
