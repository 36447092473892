import { Component, OnInit, HostListener, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "../data.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { Keywords } from "../keywords";
import { EventEmitterService } from "../event-emitter.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  stickymenuopacity = 0;
  bg: any;
  stickyMargin: any;
  scrollboolean = false;
  key: any = Keywords;
  config = {
    backdrop: true,
    ignoreBackdropClick: false,
  };
  SearchRoute: any;
  modalRef: BsModalRef;
  value = "";
  searchNoMatch: any = "none";
  private dataService: DataService;
  heightAfterScroll: string;
  constructor(
    private router: Router,
    private dService: DataService,
    private modalService: BsModalService,
    private eventEmitterService:EventEmitterService
  ) {
    this.dataService = dService;
  }

  ngOnInit() {
    const scrollPosition = window.pageYOffset;
    this.stickymenuopacity = 1;
  }
  @HostListener("window:scroll", ["$event"])
  checkScroll() {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }
  onEnter(value: any) {
    this.searchNoMatch = "none";
    this.value = value.toLowerCase() || null;
    for (let i = 0; i < this.key.length; i++) {
      if (this.value === this.key[i].keyword.toLowerCase()) {
        this.SearchRoute = this.key[i].pagenavid;
      }
    }

    if (this.SearchRoute) {
      this.router.navigate([this.SearchRoute]);
      this.modalRef.hide();
    } else {
      this.searchNoMatch = "block";
    }
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "287px";
    document.getElementById("backdrop").style.display = "block";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("backdrop").style.display = "none";

    document.getElementById("bookademotemplate").style.width = "0";
    document.body.style.backgroundColor = "white";
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("bkdemobtn").style.display = "block";
  }
  noopacitybg(routeName) {
    document.body.style.backgroundColor = "white";
    this.navigateTo(routeName);
  }

  navigateTo(link) {
    const windowpos = $(window).scrollTop();
    if (windowpos >= $("#bannerimg").innerHeight()) {
      this.dataService.setScrolled(true);
      this.router.navigate([link + ""]);
    } else {
      this.dataService.setScrolled(false);
      this.router.navigate([link + ""]);
    }
  }
  gotocovid() {
    this.router.navigate(["covid19response"]);
  }
  gotoTeam() {
    localStorage.removeItem("selectedEmpId");
    this.router.navigate(["team"]);
  }
  gotoCareers() {
    this.router.navigate(["careers"]);
  }
  gotoInjury() {
    this.router.navigate(["onlineservice/injury"]);
    this.closeNav()
    this.eventEmitterService.onClickSideReport('injury')
  }
  gotoEmployees() {
    this.router.navigate(["onlineservice/employees"]);
    this.closeNav()
    this.eventEmitterService.onClickSideReport('employees')
  }
  gotoEmployer(){
    this.router.navigate(['/onlineservices'],{ preserveFragment: true } );
    setTimeout(function () {
      let el = document.getElementById("employers");
      el.scrollIntoView({ block: "start", behavior: "auto" });
    }, 500);
  }
  gotoWcCert() {
    this.router.navigate(["onlineservice/wc-cert"]);
    this.closeNav()
    this.eventEmitterService.onClickSideReport('wc-cert')
  }
}
