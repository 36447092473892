import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-admin-header",
  templateUrl: "./admin-header.component.html",
  styleUrls: ["./admin-header.component.css"],
})
export class AdminHeaderComponent implements OnInit {
  title: string;
  constructor(public route: Router) {}

  ngOnInit() {
    this.title = localStorage.getItem("username");
  }
  logout() {
    localStorage.removeItem("x-token-code");
    this.route.navigate(["/login"]);
  }
}
