import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { AdminService } from "../../admin.service";
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-admin-add-post',
  templateUrl: './admin-add-post.component.html',
  styleUrls: ['./admin-add-post.component.css']
})
export class AdminAddPostComponent implements OnInit {

  topicForm: FormGroup;
  topicSubmitted = false;
  button = 'Save';
  displaymessage: any;
  successtoast: any;
  type = 'add';
  selectedTopic:any;
  deleteTopicId:any;
  popupHeading:any;
  popupMessage:any;
  popupButton:any;
  deleteResponseMessage:any;
  deleteItem:any;
  categoryList = [];
  selectedCategory:any;
  params:any = {};
  showSuccessToast = false;

  constructor(public service: AdminService,private activeRoute: ActivatedRoute) { 
    this.getCategories()
    this.activeRoute.params.subscribe((params) => {
      this.params = params;
    })
  }

  ngOnInit() {
    this.topicForm = new FormGroup({
      topic_name: new FormControl("", [Validators.required]),
      topic_title: new FormControl("", [Validators.required]),
      topic_content: new FormControl("", [Validators.required, Validators.minLength(3)]),
      category: new FormControl("", [Validators.required])
    });

    this.getCategories();

  }

  onSubmitTopics(){
    this.topicSubmitted = true;
    this.displaymessage = '';

    if (this.topicForm.invalid) {
      return;
    }
    this.topicForm.value.category_id = this.selectedCategory.category_id;

    this.button = 'Saving...';

    if(!this.params.topic_id){
      this.service.addTopic(this.topicForm.value).subscribe((res:any) => {
        this.successtoast = res.message;
        this.showSuccessToast = true;  
        this.button = 'Save';
        this.topicForm.reset();
        this.topicSubmitted = false;
        setTimeout(() => {
        this.successtoast = '';
        this.showSuccessToast = false;
        }, 3000);
      },(error) => {
        this.displaymessage = error.error.message;
        })
    }else{
      let data = this.topicForm.value
      data.topic_id = this.params.topic_id;
      this.service.updateTopic(data).subscribe((res:any) => {
        this.successtoast = res.message;
        this.showSuccessToast = true;
        this.button = 'Save';
        setTimeout(() => {
        this.successtoast = '';
        this.showSuccessToast = false;
        }, 3000);
      },(error) => {
        this.displaymessage = error.error.message;
        })
    }
  }

  oncategoryChange(event){
    this.selectedCategory = event.item;
  }

  getCategories(){
    this.service.getCategories().subscribe((res: any) => {
      this.categoryList = res.data;
      if(this.params.topic_id){
          this.getTopic(this.params.topic_id)
        }
    });
  }

  getTopic(topic_id){
    this.service.getTopic(topic_id).subscribe((res:any) => {
      let {topic_name, topic_title, topic_content, category_id} = res.data;
      this.selectedCategory = this.categoryList.find((e) =>{ return e.category_id == category_id})
      let obj = {
        topic_name,
        topic_title,
        topic_content,
        category:this.selectedCategory.category_name
      }
      this.topicForm.setValue(obj);
    })
  }

}
