import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-admin-sidebar",
  templateUrl: "./admin-sidebar.component.html",
  styleUrls: ["./admin-sidebar.component.css"],
})
export class AdminSidebarComponent implements OnInit {
  activeURL = "newsletter";
  menuItems = [
    { name: "Newsletter", link: "newsletter", icon: "fa fa-newspaper-o" },
    { name: "Resumes", link: "addresume", icon: "fa fa-file-text" },
    { name: "Contact Forms", link: "contactus", icon: "fa fa-phone" },
    { name: "Demo Requests", link: "demorequests", icon: "fa fa-desktop" },
    { name: "TrueBudget Subscribers", link: "truebudget", icon: "fa fa-newspaper-o" },
    { name:"Help Center", icon: "fa fa-tasks",id:'helpcenter', submenu:[
      { name:"Categories", link:"categories", icon: "fa fa-list" },
      { name:"Articles", link:"posts", icon: "fa fa-sticky-note-o"}
      ]},
    { name:"Careers", icon: "fa fa-graduation-cap", id:'careers',submenu:[
      { name:"Job Postings", link:"careers", icon: "fa fa-graduation-cap" },
      { name:"Technologies", link:"technologies", icon: "fa fa-address-card"},
      { name:"Resumes", link:"career-resumes", icon: "fa fa-file-text" }
      ]},
  ];
  constructor(private router: Router) {
    this.router.events.subscribe((event: any) => {
      this.activeURL = "newsletter";
      if (event && event.url) {
        let urls = event.url.split("/");

        urls.shift();
        if (urls.length > 1) this.activeURL = urls[1];
      }
    });
  }

  ngOnInit() {

    $('.panel-group').on('hidden.bs.collapse', this.toggleIcon);
    $('.panel-group').on('shown.bs.collapse', this.toggleIcon);

  }

  toggleIcon(e: any) {
    $(e.target)
        .prev('.panel-heading')
        .find(".more-less")
        .toggleClass('glyphicon-plus glyphicon-minus');
}
}
