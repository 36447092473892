import { Component, OnInit } from '@angular/core';
import { AdminService } from "../../admin.service";
import * as moment from "moment";
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-admin-posts',
  templateUrl: './admin-posts.component.html',
  styleUrls: ['./admin-posts.component.css']
})
export class AdminPostsComponent implements OnInit {

  constructor(private router: Router,public service: AdminService) { }

  topics:any = [];
  icons = {
    filter: " ",
  };
  columnDefs = [];
  deleteTopicId:any;
  popupHeading:any;
  popupMessage:any;
  popupButton:any;
  deleteResponseMessage:any;
  selectedTopic:any = {};

  ngOnInit() {

    this.getList()
  }

  getList(){
    this.service.getTopicsList().subscribe((res:any) => {
      this.topics = res.data;
      this.getcolumnDefs()
    })
  }

  onClickButton(params) {
    const eDiv = document.createElement("div");
    const self = this;
    eDiv.innerHTML = `<span class="my-css-class">
    <button title="View a Post" class="btn btn-sm ml-1 mt-2 mb-2 btn-view"><i class="fa fa-eye" aria-hidden="true"></i></button>
    <button title="Update a Post" class="btn btn-sm ml-1 mt-2 mb-2 btn-edit"><i class="fa fa-edit" aria-hidden="true"></i></button>
    <button title="Delete a Post" class="btn btn-sm ml-1 mt-2 mb-2 btn-delete"><i class="fa fa-trash" aria-hidden="true"></i></button>
    </span>`;

    var editButton = eDiv.querySelectorAll(".btn-edit")[0];
    editButton.addEventListener("click", function () {
        self.router.navigate([`/dashboard/edit-post/${params.data.topic_id}`]);
      });
    
      var deleteButton = eDiv.querySelectorAll(".btn-delete")[0];
      deleteButton.addEventListener("click", function () {
          self.deleteTopicId = params.data.topic_id;
          self.deleteResponseMessage = '';
          self.popupHeading = 'Delete';
          self.popupMessage = 'Do you want to delete?';
          self.popupButton = 'Confirm';
          $("#deleteModal").modal("show");
        });

      var viewButton = eDiv.querySelectorAll(".btn-view")[0];
      viewButton.addEventListener("click", function () {
        self.service.getTopic(params.data.topic_id).subscribe((res:any) => {
            self.selectedTopic = res.data;
            $("#topicModal").modal("show");
          })
        });
    return eDiv;
  }

  onClickEdit(){
    this.router.navigate([`/dashboard/edit-post/${this.selectedTopic.topic_id}`]);
    $("#topicModal").modal("hide");
  }

  getcolumnDefs(){
    this.columnDefs = [
      {
        headerName: "POST NAME",
        field: "topic_name",
        sortable: true,
        filter: true,
        width: 240,
        resizable: true
      },
      {
        headerName: "POST TITLE",
        field: "topic_title",
        sortable: true,
        filter: true,
        width: 400,
        resizable: true
      },
      {
        headerName: 'CATEGORY',
        valueGetter: (params) => {
          return (params.data.category.category_name);
        },
        sortable: true,
        filter: true,
        width: 150,
        resizable: true
      },
      {
        headerName: '',
        headerComponentParams: { template: '<i class="fa fa-thumbs-up"></i>' },
        valueGetter: (params) => {
          return params.data.feedback?(params.data.feedback["Y"] || 0) : 0
        },
        sortable: true,
        filter: true,
        width: 100,
        resizable: true
      },
      {
        headerName: '',
        headerComponentParams: { template: '<i class="fa fa-thumbs-down"></i>' },
        valueGetter: (params) => { 
          return params.data.feedback?(params.data.feedback["N"] || 0) : 0
        },
        sortable: true,
        filter: true,
        width: 100,
        resizable: true
      },
      {
        headerName: "UPDATED DATE",
        valueGetter: (params) => {
          return moment(params.data.updated_date).format("MM-DD-YYYY")
        },
        sortable: true,
        filter: true,
        width: 150,
        resizable: true
      },
      {
        headerName: "ACTIONS",
        sortable: false,
        filter: false,
        cellRenderer: this.onClickButton.bind(this),cellStyle: { "padding": 0},
        width: 150,
      },
    ]
  }

  deleteTopic(){

    this.service.deleteTopic({topic_id:this.deleteTopicId}).subscribe((res:any) => {
      this.deleteResponseMessage = res.message;
      this.getList()
      setTimeout(function () {
        $("#deleteModal").modal("hide");
        this.deleteResponseMessage = '';
      }, 3000);
    },(error) => {
      this.deleteResponseMessage = error.error.message;
    })

  }
}
