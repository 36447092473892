import { Component, OnInit } from '@angular/core';
import { AdminService } from "../admin.service";
import * as moment from "moment";
import * as FileSaver from "file-saver";

declare var $: any;

@Component({
  selector: 'app-admin-contactus',
  templateUrl: './admin-contactus.component.html',
  styleUrls: ['./admin-contactus.component.css']
})
export class AdminContactusComponent implements OnInit {

  minDate: Date;
  maxDate: Date;
  selectDate = {
    startDate: "",
    endDate: "",
  };
  tableData = [];
  icons = {
    filter: " ",
  };
  columnDefs = [
    {
      headerName: "NAME",
      field: "name",
      sortable: true,
      filter: true,
      width: 200,
      resizable: true
    },
    {
      headerName: "EMAIL ID",
      field: "email_id",
      sortable: true,
      filter: true,
      width: 250,
      resizable: true
    },
    {
      headerName: "PHONE",
      field: "mobile_number",
      sortable: true,
      filter: true,
      width: 150,
      resizable: true
    },
    {
      headerName: "DISCOVER FROM",
      field: "discover_from",
      sortable: true,
      filter: true,
      width: 150,
      resizable: true
    },
    {
      headerName: "COMMENTS",
      cellRenderer: this.onClickComments.bind(this),
      sortable: true,
      filter: true,
      width: 200,
      resizable: true
    },
    {
      headerName: "WEBSITE NAME",
      field: "website_name",
      sortable: true,
      filter: true,
      width: 130,
      resizable: true
    },
    {
      headerName: "CREATED DATE",
      field: "created_date",
      sortable: true,
      filter: true,
      width: 130,
      resizable: true
    },
    {
      headerName: "ACTIONS",
      sortable: false,
      filter: false,
      cellRenderer: function (params) {
        return (
          `<span><i class="fa fa-trash" aria-hidden="true"></i></span>`
        );
      },
      width: 80,
    },
  ];
  deleteContactId:any;
  responseMessage: string;
  viewComments:any;

  constructor(public service: AdminService) { }

  ngOnInit() {
    this.getContactForms()
  }

  getContactForms(){
    this.service.getContactForms().subscribe((res: any) => {
      this.tableData = res.data;
      this.createdIndex();
    });
  }

  onValueChange(value: Date): void {
    if (value) {
      const firstdate = value[0];
      const seconddate = value[1];
      var event = new Date(firstdate);
      var eventone = new Date(seconddate);
      let date = JSON.stringify(event);
      let datesecond = JSON.stringify(eventone);
      this.selectDate.startDate = date.slice(1, 11);
      this.selectDate.endDate = datesecond.slice(1, 11);
      this.service.filterContactForms(this.selectDate).subscribe((res: any) => {
        this.tableData = res.data;
        this.createdIndex();
      });
    }else{
      this.selectDate = {
        startDate: "",
        endDate: "",
      };
    }
  }

  downloadexcel() {
    this.service.exportContactForms(this.selectDate).subscribe(
      (res) => {
        const file = new File([res], "Contact Form Details.xlsx", {
          type: "application/vnd.ms-excel",
        });
        FileSaver.saveAs(file);
      },
      (error) => {
        console.log(error.message);
      }
    );
  }
  createdIndex() {
    this.tableData.forEach((row, i) => (row["s_no"] = i + 1));
  }

  onCellClicked(event){
    if(event.colDef.headerName == 'ACTIONS'){
      this.responseMessage = '';
      this.deleteContactId = event.data.contact_us_id
      $("#deleteModal").modal("show");
    }
  }
  deleteConfirm(){
    this.responseMessage = '';
    this.service.deleteContactForm({ID:this.deleteContactId}).subscribe(
      (response) => {
        this.responseMessage = response.message;
        this.getContactForms()
        setTimeout(function () {
          $("#deleteModal").modal("hide");
        }, 3000);
      },(error)=>{
        this.responseMessage = error.error.message;
      })
  }

  onClickComments(params) {
    const eDiv = document.createElement("div");
    const self = this;
    eDiv.innerHTML = `<span class="category-css-class">
    <button title="View a Descrition" class="btn btn-sm ml-1 mt-2 mb-2 view">View</button>
    </span>`;

    var editButton = eDiv.querySelectorAll(".view")[0];
    editButton.addEventListener("click", function () {
      self.viewComments = params.data.comments;
      $("#viewModal").modal("show");
      });
    return eDiv;
  }

}
