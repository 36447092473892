import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable()
export class LoginService {
  constructor(public http: HttpClient) { }

  /** POST: add a new hero to the database */
  addHero(hero: any): Observable<any> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post<any>(environment.apiUrl + "users/login", hero);
  }

  uploadNewsletter(hero: any): Observable<any> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return this.http.post<any>(environment.apiUrl + "newsletter/addNewsletter", hero);
  }
}
